import { create, fetch, get, remove, update } from '../client'

const authClient = {
  me: async () => {
    return get('/api/me')
  },

  confirmation: async (confirmationToken) => {
    return fetch('/auth/confirmation?confirmation_token=' + confirmationToken)
  },

  login: async (email, password) => {
    return create('/auth/sign_in', { user: { email, password } })
  },

  changePassword: async (token, password, passwordConfirmation) => {
    return update('/auth/password', {
      user: {
        reset_password_token: token,
        password,
        password_confirmation: passwordConfirmation
      }
    })
  },

  forgotPassword: async (email) => {
    return create('/auth/password', { user: { email } })
  },

  logout: async () => {
    return remove('/auth/sign_out')
  },

  unlock: async (unlockToken) => {
    return fetch('/auth/unlock?unlock_token=' + unlockToken)
  }
}

export { authClient }
