import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faSortUp } from '@fortawesome/pro-duotone-svg-icons'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useHistory } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { groupBy, sortBy } from 'underscore'
import Spinner from '../../shared/spinner'
import { client } from '../../../lib/client'

export default function FAQQuestionListView () {
  const [questions, setQuestions] = useState([])
  const [categories, setCategories] = useState([])
  const [processing, setProcessing] = useState(true)

  const groupedQuestions = groupBy(sortBy(questions, 'sort_order'), 'faq_category_id')
  const history = useHistory()

  useEffect(() => {
    async function fetchData () {
      setCategories(await client.faq.categories.getAll())
      setQuestions(await client.faq.questions.getAll({ show_all: true }))
      setProcessing(false)
    }

    fetchData()
  }, [])

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = async (result) => {
    const { source, destination } = result
    const categoryQuestions = groupedQuestions[source.droppableId]

    if (!destination || destination.droppableId !== source.droppableId) {
      return
    }

    const sortedQuestions = reorder(categoryQuestions, source.index, destination.index).map((question, index) => {
      question.sort_order = index + 1

      return question
    })

    setQuestions(
      questions.map((question) => {
        const sortedQuestion = sortedQuestions.find((sortedQuestion) => sortedQuestion.id === question.id)

        if (sortedQuestion) {
          question.sort_order = sortedQuestion.sort_order
        }

        return question
      })
    )

    await Promise.all(
      sortedQuestions.map(
        async (question, index) => (
          client.faq.questions.update(question.id, { sort_order: question.sort_order })
        )
      )
    )
  }

  const navigateToQuestionNewView = () => {
    history.push('/faq/questions/new')
  }

  const navigateToQuestionEditView = (roleId) => {
    history.push(`/faq/questions/${roleId}/edit`)
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-10'>
          <Breadcrumb>
            <Breadcrumb.Item active>Questions</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className='col-2'>
          <Button variant='success' type='submit' className='float-right' onClick={ navigateToQuestionNewView }>
            <FontAwesomeIcon icon={ faPlus } />
            &nbsp;New
          </Button>
        </div>
      </div>

      <table className='table'>
        <thead>
          <tr>
            <th style={ { width: '69%' } }>Question</th>
            <th style={ { width: '10%' } }>Status</th>
            <th style={ { width: '10%' } }>State</th>
            <th style={ { width: '10%' } }>Actions</th>
            <th style={ { width: '1%' } }></th>
          </tr>
        </thead>

        <DragDropContext onDragEnd={ onDragEnd }>
          {
            categories.map((category) => (
              <Droppable key={ category.id } droppableId={ String(category.id) } type={ category.name }>
                {
                  (provided, snapshot) => (
                    <tbody { ...provided.droppableProps } ref={ provided.innerRef }>
                      <tr>
                        <td className='bg-secondary text-white' colSpan='5'>
                          <strong>{ category.name }</strong>
                        </td>
                      </tr>

                      {
                        (groupedQuestions[category.id] || []).map((question, index) => (
                          <Draggable key={ question.id } draggableId={ String(question.id) } type={ category.name } index={ index }>
                            {
                              (provided, snapshot) => {
                                const style = {
                                  display: snapshot.isDragging ? 'table' : '',
                                  backgroundColor: 'white',
                                  ...provided.draggableProps.style
                                }

                                return (
                                  <tr ref={ provided.innerRef } { ...provided.draggableProps } style={ style }>
                                    <td style={ { width: '69%' } }>{ question.question }</td>

                                    <td style={ { width: '10%' } }>{ question.status }</td>

                                    <td style={ { width: '10%' } }>{ question.state && question.state.name }</td>

                                    <td style={ { width: '10%' } }>
                                      <Button variant='primary' className='float-left mr-2' onClick={ () => navigateToQuestionEditView(question.id) }>
                                        <FontAwesomeIcon icon={ faEdit } />
                                      </Button>
                                    </td>

                                    <td style={ { width: '1%', fontSize: '20px', textAlign: 'center' } } { ...provided.dragHandleProps }>
                                      <FontAwesomeIcon icon={ faSortUp } />
                                    </td>
                                  </tr>
                                )
                              }
                            }
                          </Draggable>
                        ))
                      }

                      { provided.placeholder }
                    </tbody>
                  )
                }
              </Droppable>
            ))
          }
        </DragDropContext>
      </table>
    </div>
  )
}
