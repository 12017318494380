import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { range } from 'underscore'

export default function PopulationListView () {
  const history = useHistory()

  const navigateToPopulationEditView = (year) => {
    history.push(`/populations/${year}`)
  }

  return (
    <div>
      <div className='row mb-4'>
        <div className='col-10'>
          <Breadcrumb>
            <Breadcrumb.Item active>Populations</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className='row'>
        {
          range(2020, (new Date().getFullYear() + 1)).reverse().map((year) =>
            <div key={ year } className='float-left col-1 mb-3'>
              <Button variant='primary' onClick={ () => navigateToPopulationEditView(year) }>{ year }</Button>
            </div>
          )
        }
      </div>

      <div className='row'>
        {
          range(2010, 2020).reverse().map((year) =>
            <div key={ year } className='float-left col-1 mb-3'>
              <Button variant='primary' onClick={ () => navigateToPopulationEditView(year) }>{ year }</Button>
            </div>
          )
        }
      </div>

      <div className='row'>
        {
          range(2000, 2010).reverse().map((year) =>
            <div key={ year } className='float-left col-1 mb-3'>
              <Button variant='primary' onClick={ () => navigateToPopulationEditView(year) }>{ year }</Button>
            </div>
          )
        }
      </div>
    </div>
  )
}
