import { create, get, remove, update } from '../client'

const userClient = {
  getAll: async () => {
    return (await get('/api/users')).users
  },

  get: async (userId) => {
    return get(`/api/users/${userId}`)
  },

  create: async (params) => {
    return create('/api/users', params)
  },

  update: async (userId, params) => {
    return update(`/api/users/${userId}`, params)
  },

  remove: async (userId) => {
    return remove(`/api/users/${userId}`)
  }
}

export { userClient }
