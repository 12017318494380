import { create, get, remove, update } from '../client'

const tokenClient = {
  getAll: async () => {
    return (await get('/api/tokens')).tokens
  },

  get: async (tokenId) => {
    return get(`/api/tokens/${tokenId}`)
  },

  create: async (params) => {
    return create('/api/tokens', params)
  },

  update: async (tokenId, params) => {
    return update(`/api/tokens/${tokenId}`, params)
  },

  remove: async (tokenId) => {
    return remove(`/api/tokens/${tokenId}`)
  }
}

export { tokenClient }
