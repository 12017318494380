import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function TokenShowView (props) {
  const history = useHistory()
  const { tokenId } = useParams()

  const [token, setToken] = useState({})
  const [confirmText, setConfirmText] = useState('')
  const [processing, setProcessing] = useState(true)

  useEffect(() => {
    async function fetchData () {
      setToken(await client.tokens.get(tokenId))
      setProcessing(false)
    }

    fetchData()
  }, [])

  const navigateToTokenListPage = () => {
    history.push('/tokens')
  }

  const removeToken = async () => {
    setProcessing(true)

    try {
      await client.tokens.remove(tokenId)

      toast.success('Token was deleted successfully')

      navigateToTokenListPage()
    } catch (error) {
      setProcessing(false)

      toast.error(error.errors.join(', '))
    }
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToTokenListPage }>Tokens</Breadcrumb.Item>
            <Breadcrumb.Item>{ token.description }</Breadcrumb.Item>
            <Breadcrumb.Item active>Confirm deletion</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div>
        <Row>
          <Col className='col-1'>
            <strong>Description:</strong>
          </Col>

          <Col className='col-11'>
            { token.description }
          </Col>
        </Row>

        <Row className='mt-2'>
          <Col className='col-1 pt-1'>
            <strong>Confirm:</strong>
          </Col>

          <Col className='col-11'>
            <Form>
              <Form.Control
                type='text'
                name='confirm'
                onChange={ (e) => setConfirmText(e.target.value) } />
            </Form>
          </Col>
        </Row>

        <Alert variant='danger' className='mt-3'>
          <p>
            Enter the description of the token in the <strong>CONFIRM</strong> field and click the <strong>DELETE</strong> button to delete this token.
          </p>

          <p className='mb-0'>Deleting a token immediately disables that token for API purposes.</p>
        </Alert>

        <Button variant='danger' type='submit' disabled={ token.description !== confirmText } onClick={ removeToken }>
          Delete
        </Button>
      </div>
    </div>
  )
}

TokenShowView.propTypes = {
  tokenId: PropTypes.number
}
