import { create, get, update } from '../client'

const stateClient = {
  getAll: async () => {
    return (await get('/api/states')).states
  },

  get: async (stateId) => {
    return get(`/api/states/${stateId}`)
  },

  create: async (params) => {
    return create('/api/states', params)
  },

  update: async (stateId, params) => {
    return update(`/api/states/${stateId}`, params)
  }
}

export { stateClient }
