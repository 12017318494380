import Humanize from 'humanize-plus'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'
import { useAuth } from '../../lib/auth/context'

export default function ProfileShowView () {
  const { user } = useAuth()

  const [currentTab, setCurrentTab] = useState('general')
  const [processing, setProcessing] = useState(false)

  const { handleSubmit, register, reset } = useForm()

  // Update form default values for edit action
  useEffect(() => {
    if (Object.keys(user).length) { reset(user) }
  }, [user])

  const onSubmit = async (params) => {
    setProcessing(true)

    try {
      await client.users.update(user.id, params)

      toast.success('Settings were updated successfully')
    } catch (error) {
      toast.error(error.errors.join(', '))
    }

    setProcessing(false)
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item active>Profile settings</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <Card>
        <Row noGutters className="row-bordered row-border-light">
          <Col md={3} className="pt-0">
            <Nav className="account-settings-links list-group list-group-flush" onSelect={ setCurrentTab } activeKey={ currentTab }>
              <Nav.Link as={ListGroup.Item} eventKey="general" className="list-group-item-action cursor-pointer">
                General
              </Nav.Link>

              {
                false && (
                  <Nav.Link as={ListGroup.Item} eventKey="password" className="list-group-item-action cursor-pointer">
                    Change password
                  </Nav.Link>
                )
              }
            </Nav>
          </Col>

          {
            currentTab === 'general' && (
              <Col md={9}>
                <Card.Body>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>

                    <Form.Control
                      type='text'
                      name='first_name'
                      ref={ register() } />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>

                    <Form.Control
                      type='text'
                      name='last_name'
                      ref={ register() } />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Email</Form.Label>

                    <Form.Control
                      type='text'
                      name='email'
                      ref={ register() } />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Role</Form.Label>

                    <Form.Control value={ Humanize.capitalize(user.role.name) } disabled={ true } />
                  </Form.Group>

                  {
                    user.provider && (
                      <Form.Group>
                        <Form.Label>Provider</Form.Label>

                        <Form.Control value={ Humanize.capitalize(user.provider.name) } disabled={ true } />
                      </Form.Group>
                    )
                  }
                </Card.Body>
              </Col>
            )
          }

          {
            currentTab === 'password' && (
              <Col md={9}>
                <Card.Body className="pb-2">
                  <Form.Group>
                    <Form.Label>New password</Form.Label>

                    <Form.Control
                      type='password'
                      name='password'
                      ref={ register() } />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Repeat new password</Form.Label>

                    <Form.Control
                      type='password'
                      name='password_confirmation'
                      ref={ register() } />
                  </Form.Group>
                </Card.Body>
              </Col>
            )
          }
        </Row>
      </Card>

      <div className="text-right mt-3">
        <Button variant="primary" onClick={ handleSubmit(onSubmit) }>
          Save changes
        </Button>&nbsp;

        <Button variant="default" onClick={ () => window.location.reload() }>Cancel</Button>
      </div>
    </div>
  )
}
