import React from 'react'
import { CookiesProvider } from 'react-cookie'
import CookieBanner from 'react-cookie-banner'
import ReactDOM from 'react-dom'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fad } from '@fortawesome/pro-duotone-svg-icons'

import { matchesRoute, Routes } from './routes'

import { AuthProvider } from './lib/auth/provider'

import Sidenav from './components/layouts/sidenav'
import Footer from './components/layouts/footer'
import Navbar from './components/layouts/navbar'

library.add(fad)

const App = () => {
  const location = useLocation()

  if (
    location.pathname.includes('sign_in') ||
    location.pathname.includes('change_password') ||
    location.pathname.includes('forgot_password') ||
    location.pathname.includes('council') ||
    !matchesRoute(location.pathname)
  ) {
    return <Routes />
  }

  return (
    <div className="layout-wrapper layout-1 layout-footer-fixed">
      <div className="layout-inner">
        <Navbar />

        <div className="layout-container">
          <Sidenav />

          <div className="layout-content">
            <div className="container-fluid flex-grow-1 container-p-y">
              <Routes />
            </div>

            <Footer />
          </div>
        </div>
      </div>

      <div className="layout-overlay layout-sidenav-toggle"></div>

      <CookieBanner
        styles={{ banner: { position: 'absolute', bottom: 0 } }}
        message='This website uses cookies to improve your experience. By using our website, you accept our use of cookies.' />
    </div>
  )
}

ReactDOM.render(
  <BrowserRouter>
    <CookiesProvider>
      <AuthProvider>
        <ToastContainer
          position="top-right"
          autoClose={ 5000 }
          closeOnClick
          pauseOnFocusLoss
          pauseOnHover />

        <App />
      </AuthProvider>
    </CookiesProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
