import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Row from 'react-bootstrap/Row'
import Tooltip from 'react-bootstrap/Tooltip'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function TokenFormView (props) {
  const history = useHistory()

  const [token, setToken] = useState({})
  const [processing, setProcessing] = useState(false)

  const { handleSubmit, register } = useForm()

  const isJWTAvailable = () => !!token.jwt

  const navigateToTokenListPage = () => {
    history.push('/tokens')
  }

  const onSubmit = async (params) => {
    setProcessing(true)

    try {
      setToken(await client.tokens.create({ ...params, public: true }))

      toast.success('Token was created successfully')
    } catch (error) {
      toast.error(error.errors.join(', '))
    }

    setProcessing(false)
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToTokenListPage }>Tokens</Breadcrumb.Item>
            <Breadcrumb.Item active>{ isJWTAvailable() ? token.description : 'New' }</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      {
        isJWTAvailable() ? (
          <div>
            <Row>
              <Col className='col-1'>
                <strong>Description:</strong>
              </Col>

              <Col className='col-11'>
                { token.description }
              </Col>
            </Row>

            <Row className='mt-2'>
              <Col className='col-1 pt-4'>
                <strong>Token:</strong>
              </Col>

              <Col className='col-11'>
                <OverlayTrigger overlay={ <Tooltip>{ 'Copy to clipboard' }</Tooltip> }>
                  <CopyToClipboard text={ token.jwt } onCopy={ () => toast.success('Token was copied to clipboard') }>
                    <Card body role='button'>{ token.jwt }</Card>
                  </CopyToClipboard>
                </OverlayTrigger>
              </Col>
            </Row>

            <Alert variant='danger' className='mt-3'>
              <Alert.Heading>This is the only time this secret token will displayed!</Alert.Heading>

              <ul>
                <li>This token grants access to specific areas of R3Dashboard.org.</li>
                <li>It should be immediately copied and stored in a safe location.</li>
                <li>It should be protected in the same manner as any login/password information.</li>
              </ul>
            </Alert>
          </div>
        ) : (
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Description

                    <span className='ml-1 text-primary'>*</span>
                  </Form.Label>

                  <Form.Control
                    type='text'
                    name='description'
                    ref={ register() } />
                </Form.Group>
              </Col>
            </Row>

            <div className='row'>
              <div className='col'>
                <div className='pt-3'>
                  <Button variant='warning' type='submit' onClick={ handleSubmit(onSubmit) }>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )
      }
    </div>
  )
}
