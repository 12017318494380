import React from 'react'
import { Breadcrumb, Card, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/pro-duotone-svg-icons'

const MethodologyView = () => {
  return (
    <div>
      <Row className='mb-4'>
        <Col className='col-10'>
          <Breadcrumb>
          <Breadcrumb.Item active>R3Dashboard Methodology</Breadcrumb.Item>
          <Breadcrumb.Item active>Last Updated: April 26, 2021</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h2>Interpreting the dashboard</h2>
              <p>The data dashboard is a tool which combines various state’s hunting and fishing license sales data to create visualizations informing trends in purchases by various demographic sub-groups. As such, there are several factors that could lead to misinterpretation of the data, which we hope to clarify below. Please look at the methodology and documentation pages to learn about specifics of the dashboard’s construction.</p>
              <h4>Participants</h4>
              <p>Reporting of participants represents the number of individuals between the age of 18 and 64 who currently hold active licenses within a participating state. This metric does not track activity of those individuals and as such numbers may differ. Additionally, while individuals outside that age range may participate in hunting or fishing activities throughout the year, due to variances in required licensure from state to state, we only track those individuals that are required to purchase licenses in all participating states to provide the best cross-state comparison.</p>
              <h4>Licenses</h4>
              <p>This analysis is an aggregation of multiple state’s data, which differ based on state specific licensing requirements. As a result of those differences, values reported for each state may be different from those values an individual state reports due to our adjustments to make values comparable across states.</p>
              <h4>Aggregation</h4>
              <p>The licenses used in the analysis are those which grant basic privileges and some tags or permits which are used to identify specific sub-groups of recreators (i.e. archery permits for bowhunting). Other licenses such as stamps for specific species or permits for a given area are not included in the listing of total sales due to the core purpose of this project which is to measure R3 trends in outdoor recreation participants, and the total number of sales of any given license/stamp/permit do not actively contribute towards that goal.</p>
              <h4>Differences from Southwick’s Semi-annual Data-Dashboard</h4>
              <p>Some of you may be familiar with our prior work on <a href='https://asafishing.org/data-dashboard' target='_blank' rel='noreferrer'>displaying license data for hunting and fishing <FontAwesomeIcon icon={ faExternalLinkAlt } /></a>, which has evolved in an effort to produce more current data for stakeholders throughout the year into this project.</p>
              <p>One notable difference between the two dashboards are that this dashboard will not report churn (a metric which measure the percentage of a year’s participants who do not purchase a license in the following year) which is produced in the end of year dashboard. Another difference is that this Real-time Dashboard will break purchases out into months, allowing better tracking of license trends from year to year.</p>
              <p>The demographic sub-groups which remain the same from the semi-annual dashboard, will be more interactive in this version, allowing visitors to not only view breakdowns of purchases by age category, gender, and residency but by combinations of those as well (e.g. 25 to 34-year-old Male residents).</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default MethodologyView
