import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../../shared/spinner'
import Trix from '../../shared/trix'
import { client } from '../../../lib/client'

export default function FAQQuestionFormView (props) {
  const { questionId } = useParams()
  const history = useHistory()

  const [question, setQuestion] = useState({})
  const [categories, setCategories] = useState([])
  const [states, setStates] = useState([])
  const [processing, setProcessing] = useState(true)

  const { control, handleSubmit, register, reset } = useForm({
    defaultValues: {
      answer: '',
      active: true
    }
  })

  // Update form default values for edit action
  useEffect(() => {
    if (question.id) {
      question.status = question.status.toLowerCase()

      reset(question)
    }
  }, [question])

  useEffect(() => {
    async function fetchData () {
      setCategories(await client.faq.categories.getAll())
      setStates(await client.states.getAll())

      if (isEditForm()) {
        setQuestion(await client.faq.questions.get(questionId))
      }

      setProcessing(false)
    }

    fetchData()
  }, [])

  const isEditForm = () => !!questionId

  const navigateToQuestionListPage = () => {
    history.push('/faq/questions')
  }

  const onSubmit = async (params) => {
    setProcessing(true)

    try {
      if (isEditForm()) {
        await client.faq.questions.update(questionId, params)

        toast.success('Question was updated successfully')
      } else {
        await client.faq.questions.create(params)

        toast.success('Question was created successfully')
      }

      navigateToQuestionListPage()
    } catch (error) {
      setProcessing(false)

      toast.error(error.errors.join(', '))
    }
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToQuestionListPage }>Questions</Breadcrumb.Item>

            {
              isEditForm() ? (
                <>
                  <Breadcrumb.Item>{ question.question }</Breadcrumb.Item>
                  <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </>
              ) : (
                <Breadcrumb.Item active>New</Breadcrumb.Item>
              )
            }
          </Breadcrumb>
        </div>
      </div>

      <Form>
        <Form.Group>
          <Form.Label>
            Status

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control as='select' name='status' ref={ register }>
            <option value='preview'>Preview</option>
            <option value='published'>Published</option>
            <option value='archive'>Archive</option>
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Category

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control as='select' name='faq_category_id' ref={ register }>
            {
              categories.map((category) => <option value={ category.id } key={ category.id }>{ category.name }</option>)
            }
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Question

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control
            type='text'
            name='question'
            ref={ register } />
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Answer

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Trix
            name='answer'
            control={ control } />
        </Form.Group>

        <Form.Group>
          <Form.Label>State</Form.Label>

          <Form.Control as='select' name='state_id' ref={ register }>
            <option></option>
            {
              states.map((state) => <option value={ state.id } key={ state.id }>{ state.name }</option>)
            }
          </Form.Control>
        </Form.Group>

        <div className='row'>
          <div className='col'>
            <div className='pt-3'>
              <Button variant='warning' type='submit' onClick={ handleSubmit(onSubmit) }>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

FAQQuestionFormView.propTypes = {
  questionId: PropTypes.number
}
