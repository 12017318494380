import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Moment from 'react-moment'
import { useParams, useHistory } from 'react-router-dom'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function DatafileListView (props) {
  const { stateId } = useParams()
  const history = useHistory()

  const [state, setState] = useState({})
  const [datafiles, setDatafiles] = useState([])
  const [processing, setProcessing] = useState(true)

  useEffect(() => {
    async function fetchData () {
      setState(await client.states.get(stateId))
      setDatafiles(await client.datafiles.getAllByState(stateId))
      setProcessing(false)
    }

    fetchData()
  }, [])

  const navigateToStateListPage = () => {
    history.push('/states')
  }

  const navigateToStateShowPage = () => {
    history.push(`/states/${stateId}`)
  }

  const confirmDatafile = async (datafileId) => {
    setProcessing(true)

    await client.datafiles.confirm(datafileId)

    setDatafiles(await client.datafiles.getAllByState(stateId))
    setProcessing(false)
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToStateListPage }>States</Breadcrumb.Item>
            <Breadcrumb.Item onClick={ navigateToStateShowPage }>{ state.name }</Breadcrumb.Item>
            <Breadcrumb.Item active>Datafiles</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <table className='table'>
        <thead>
          <tr>
            <th>Id</th>
            <th>Datatype</th>
            <th>Version</th>
            <th>Status</th>
            <th>Created</th>
            <th>Filename</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {
            datafiles.map((datafile) => (
              <tr key={ datafile.id }>
                <td>
                  { datafile.id }
                </td>

                <td>
                  { datafile.type }
                </td>

                <td>
                  { datafile.version }
                </td>

                <td>
                  { datafile.status }
                </td>

                <td>
                  <Moment date={ datafile.created_at } format='L' />
                </td>

                <td>
                  { datafile.filename }
                </td>

                <td>
                  {
                    datafile.status === 'transformed' && (
                      <Button onClick={ () => confirmDatafile(datafile.id) }>
                        Confirm
                      </Button>
                    )
                  }
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

DatafileListView.propTypes = {
  stateId: PropTypes.number
}
