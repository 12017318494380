import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { useParams, useHistory } from 'react-router-dom'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function ProviderShowView () {
  const { providerId } = useParams()
  const history = useHistory()

  const [provider, setProvider] = useState({})
  const [processing, setProcessing] = useState(true)

  useEffect(() => {
    async function fetchData () {
      setProvider(await client.providers.get(providerId))
      setProcessing(false)
    }

    fetchData()
  }, [])

  const navigateToProviderListPage = () => {
    history.push('/providers')
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToProviderListPage }>Providers</Breadcrumb.Item>
            <Breadcrumb.Item active>{ provider.name }</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div>
        Name: { provider.name }<br />
        Abbreviation: { provider.abbreviation }
      </div>
    </div>
  )
}

ProviderShowView.propTypes = {
  providerId: PropTypes.number
}
