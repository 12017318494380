import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { client } from '../../lib/client'
import { useAuth } from '../../lib/auth/context'
import SignInLogo from 'images/sign-in-logo.png'

const AuthLoginView = (props) => {
  const { fetchLoggedUser } = useAuth()
  const { handleSubmit, register } = useForm()
  const [goBack, setGoBack] = useState('/')

  const history = useHistory()

  useEffect(() => {
    if (history.location.search.length) {
      const path = queryString.parse(history.location.search).goback

      if (path) {
        setGoBack(path)
      }
    }
  }, [])

  const prevent = (e) => {
    e.preventDefault()
  }

  const navigateToForgotPasswordPage = () => {
    history.push('/users/forgot_password')
  }

  const onSubmit = async (params) => {
    try {
      await client.auth.login(params.email, params.password)

      await fetchLoggedUser()

      // IMPORTANT a full reload here is necessary here to provide a new fresh CSRF token
      window.location = goBack
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <div className="authentication-wrapper authentication-1 px-4">
      <div className="authentication-inner py-5">

        <div className="d-flex justify-content-center align-items-center">
          <div className="ui-w-65">
            <div className="w-100 position-relative">
              <a href="/">
                <img src={ SignInLogo } />
              </a>
            </div>
          </div>
        </div>

        <form className="my-5">
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control name="email" ref={ register() } />
          </Form.Group>

          <Form.Group>
            <Form.Label className="d-flex justify-content-between align-items-end">
              <div>Password</div>

              <a href="#" onClick={ navigateToForgotPasswordPage } className="d-block small">Forgot password?</a>
            </Form.Label>

            <Form.Control type="password" name="password" ref={ register() } />
          </Form.Group>

          <div className="d-flex justify-content-between align-items-center m-0">
            <Form.Check type="checkbox" custom label="Remember me" className="m-0" id="login-remember-me" />

            <Button type="submit" variant="primary" onClick={ handleSubmit(onSubmit) }>Sign In</Button>
          </div>
        </form>

        {
          false && (
            <div className="text-center text-muted">
              Don&apos;t have an account yet? <a href="#" onClick={ prevent }>Sign Up</a>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default AuthLoginView
