import { create, get, update } from '../client'

const licenseTypeClient = {
  getAllByState: async (stateId) => {
    return (await get(`/api/states/${stateId}/license_types`)).license_types
  },

  getAll: async () => {
    return (await get('/api/license_types')).license_types
  },

  get: async (licenseTypeId) => {
    return get(`/api/license_types/${licenseTypeId}`)
  },

  create: async (params) => {
    return create('/api/license_types', params)
  },

  update: async (licenseTypeId, params) => {
    return update(`/api/license_types/${licenseTypeId}`, params)
  }
}

export { licenseTypeClient }
