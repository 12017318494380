import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { chunk } from 'underscore'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function RegionFormView (props) {
  const { regionId } = useParams()
  const history = useHistory()

  const [states, setStates] = useState([])
  const [region, setRegion] = useState({})
  const [processing, setProcessing] = useState(true)

  const { handleSubmit, register, reset } = useForm()

  // Update form default values for edit action
  useEffect(() => {
    if (region.id) {
      region.state_ids = region.states.map((lt) => String(lt.id))

      reset(region)
    }
  }, [region])

  useEffect(() => {
    async function fetchData () {
      setStates(await client.states.getAll())

      if (isEditForm()) {
        setRegion(await client.regions.get(regionId))
      }

      setProcessing(false)
    }

    fetchData()
  }, [])

  const isEditForm = () => !!regionId

  const navigateToRegionListPage = () => {
    history.push('/regions')
  }

  const navigateToRegionPage = () => {
    history.push(`/regions/${regionId}`)
  }

  const onSubmit = async (params) => {
    setProcessing(true)

    try {
      if (isEditForm()) {
        await client.regions.update(regionId, params)

        toast.success('Region was updated successfully')
      } else {
        await client.regions.create(params)

        toast.success('Region was created successfully')
      }

      navigateToRegionListPage()
    } catch (error) {
      setProcessing(false)

      toast.error(error.errors.join(', '))
    }
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToRegionListPage }>Regions</Breadcrumb.Item>

            {
              isEditForm() ? (
                <>
                  <Breadcrumb.Item onClick={ navigateToRegionPage }>
                    { region.name }
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </>
              ) : (
                <Breadcrumb.Item active>New</Breadcrumb.Item>
              )
            }
          </Breadcrumb>
        </div>
      </div>

      <Form>
        <Form.Group>
          <Form.Label>
            Name

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control
            type='text'
            name='name'
            ref={ register } />
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Subdomain

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control
            type='text'
            name='subdomain'
            style={ { textTransform: 'lowercase' } }
            ref={ register } />
        </Form.Group>

        <fieldset>
          <legend>States</legend>

          {
            chunk(states, 4).map((chunkedStates) =>
              <div className='row mb-3' key={ chunkedStates.toString() }>
                {
                  chunkedStates.map((state) =>
                    <div className='col-3' key={ `${state.name}Col` }>
                      <Form.Check
                        id={ `region_state_${state.name}` }
                        type='checkbox'
                        key={ state.name }
                        name='state_ids[]'
                        value={ state.id }
                        ref={ register }
                        label={ state.name } />
                    </div>
                  )
                }
              </div>
            )
          }
        </fieldset>

        <div className='row'>
          <div className='col'>
            <div className='pt-3'>
              <Button variant='warning' type='submit' onClick={ handleSubmit(onSubmit) }>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

RegionFormView.propTypes = {
  regionId: PropTypes.number
}
