import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBadgeCheck, faCog, faChartBar, faDoorOpen, faFileCertificate, faMap,
  faRouteInterstate, faTags, faUser, faUsersClass, faQuestionCircle,
  faTachometerAlt, faIdCard, faMoneyBill, faRunning, faEraser,
  faWrench
} from '@fortawesome/pro-duotone-svg-icons'
import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SideNav as OriginalSideNav } from 'sidenav'
import { useAuth } from '../../lib/auth/context'
import sideBarLogoSa from 'images/sa-sidebar.png'
import sideBarLogoCouncil from 'images/council-sidebar.png'
import sideBarLogoWsfr from 'images/wsfr-sidebar.png'

const Sidenav = () => {
  const { abilities, isAuthenticated } = useAuth()
  const location = useLocation()

  const isActive = (pathname) => location.pathname.includes(pathname)

  const isAccessMgmtActive = () => {
    return ['roles', 'users']
      .some((pathname) => location.pathname.includes(pathname))
  }

  const isSystemConfigActive = () => {
    return ['faq', 'license_types', 'populations', 'providers', 'regions', 'states']
      .some((pathname) => location.pathname.includes(pathname))
  }

  const clearLocalStorage = () => {
    window.localStorage.clear()
    window.location.reload()
  }

  useEffect(() => {
    // TODO: Find a better way to do this properly
    $('#layout-sidenav').each(function () {
      new OriginalSideNav(this, { // eslint-disable-line no-new
        orientation: $(this).hasClass('sidenav-horizontal') ? 'horizontal' : 'vertical'
      })
    })
  }, [])

  return (
    <div id='layout-sidenav' className='layout-sidenav sidenav-vertical sidenav bg-sidenav-theme'>

      <div className='sidenav-divider mt-0'></div>

      <ul className='sidenav-inner py-1'>
        <li className={ ['sidenav-item', (isActive('visualizations') ? 'active' : ''), (isAuthenticated ? 'open' : '')].join(' ') }>
          <a className='sidenav-link sidenav-toggle' href='#'>
            <FontAwesomeIcon icon={ faChartBar } className='sidenav-icon' />

            <div>Visualizations</div>
          </a>

          <ul className='sidenav-menu'>
            <li className={ ['sidenav-item', (isActive('dashboard') ? 'active' : '')].join(' ') }>
              <Link to='/visualizations/dashboard' className='sidenav-link'>
                <FontAwesomeIcon icon={ faTachometerAlt } className='sidenav-icon' />

                <div>Dashboard</div>
              </Link>
            </li>

            <li className={ ['sidenav-item', (isActive('purchases') ? 'active' : '')].join(' ') }>
              <Link to='/visualizations/purchases' className='sidenav-link'>
                <FontAwesomeIcon icon={ faMoneyBill } className='sidenav-icon' />

                <div>Daily Purchases</div>
              </Link>
            </li>

            <li className={ ['sidenav-item', (isActive('license_holders') ? 'active' : '')].join(' ') }>
              <Link to='/visualizations/license_holders' className='sidenav-link'>
                <FontAwesomeIcon icon={ faIdCard } className='sidenav-icon' />

                <div>License Holders</div>
              </Link>
            </li>

          </ul>
        </li>

        {
          isAuthenticated && abilities.can('manage', 'admin') &&
            <>
              <li className='sidenav-divider mb-1' />
              <li className='sidenav-header small font-weight-semibold'>
                Administration
              </li>

              <li className={ ['sidenav-item', (isAccessMgmtActive() ? 'active open' : '')].join(' ') }>
                <a className='sidenav-link sidenav-toggle' href='#'>
                  <FontAwesomeIcon icon={ faDoorOpen } className='sidenav-icon' />

                  <div>Access Mgmt</div>
                </a>

                <ul className='sidenav-menu'>
                  {
                    abilities.can('read', 'User') && (
                      <li className={ ['sidenav-item', (isActive('users') ? 'active' : '')].join(' ') }>
                        <Link className='sidenav-link' to='/users'>
                          <FontAwesomeIcon icon={ faUser } className='sidenav-icon' />

                          <div>Users</div>
                        </Link>
                      </li>
                    )
                  }

                  {
                    abilities.can('read', 'Role') && (
                      <li className={ ['sidenav-item', (isActive('roles') ? 'active' : '')].join(' ') }>
                        <Link className='sidenav-link' to='/roles'>
                          <FontAwesomeIcon icon={ faTags } className='sidenav-icon' />

                          <div>Roles</div>
                        </Link>
                      </li>
                    )
                  }
                </ul>
              </li>

              <li className={ ['sidenav-item', (isSystemConfigActive() ? 'active open' : '')].join(' ') }>
                <a className='sidenav-link sidenav-toggle' href='#'>
                  <FontAwesomeIcon icon={ faCog } className='sidenav-icon' />

                  <div>System Config</div>
                </a>

                <ul className='sidenav-menu'>
                  {
                    abilities.can('read', 'Provider') && (
                      <li className={ ['sidenav-item', (isActive('providers') ? 'active' : '')].join(' ') }>
                        <Link className='sidenav-link' to='/providers'>
                          <FontAwesomeIcon icon={ faBadgeCheck } className='sidenav-icon' />

                          <div>Providers</div>
                        </Link>
                      </li>
                    )
                  }

                  {
                    abilities.can('read', 'LicenseType') && (
                      <li className={ ['sidenav-item', (isActive('license_types') ? 'active' : '')].join(' ') }>
                        <Link className='sidenav-link' to='/license_types'>
                          <FontAwesomeIcon icon={ faFileCertificate } className='sidenav-icon' />

                          <div>License Types</div>
                        </Link>
                      </li>
                    )
                  }

                  {
                    abilities.can('read', 'State') && (
                      <li className={ ['sidenav-item', (isActive('states') ? 'active' : '')].join(' ') }>
                        <Link className='sidenav-link' to='/states'>
                          <FontAwesomeIcon icon={ faRouteInterstate } className='sidenav-icon' />

                          <div>States</div>
                        </Link>
                      </li>
                    )
                  }

                  {
                    abilities.can('read', 'Region') && (
                      <li className={ ['sidenav-item', (isActive('regions') ? 'active' : '')].join(' ') }>
                        <Link className='sidenav-link' to='/regions'>
                          <FontAwesomeIcon icon={ faMap } className='sidenav-icon' />

                          <div>Regions</div>
                        </Link>
                      </li>
                    )
                  }

                  {
                    abilities.can('read', 'FAQ::Question') && (
                      <li className={ ['sidenav-item', (isActive('faq') ? 'active' : '')].join(' ') }>
                        <Link className='sidenav-link' to='/faq/questions'>
                          <FontAwesomeIcon icon={ faQuestionCircle } className='sidenav-icon' />

                          <div>FAQ</div>
                        </Link>
                      </li>
                    )
                  }

                  {
                    abilities.can('read', 'Population') && (
                      <li className={ ['sidenav-item', (isActive('populations') ? 'active' : '')].join(' ') }>
                        <Link className='sidenav-link' to='/populations'>
                          <FontAwesomeIcon icon={ faUsersClass } className='sidenav-icon' />

                          <div>Population</div>
                        </Link>
                      </li>
                    )
                  }

                </ul>
              </li>
              </>
        }

        {
          isAuthenticated && (abilities.can('manage', 'admin') || abilities.can('clear_storage', 'admin')) &&
            <>

              <li className={ ['sidenav-item'].join(' ') }>
                <a className='sidenav-link sidenav-toggle' href='#'>
                  <FontAwesomeIcon icon={ faWrench } className='sidenav-icon' />

                  <div>System Tools</div>
                </a>

                <ul className='sidenav-menu'>

                  {
                    abilities.can('manage', 'admin') && (
                      <li className={ ['sidenav-item', (isActive('sidekiq') ? 'active' : '')].join(' ') }>
                        <a className='sidenav-link' href='/sidekiq' target='_blank'>
                          <FontAwesomeIcon icon={ faRunning } className='sidenav-icon' />

                          <div>SideKiq</div>
                        </a>
                      </li>
                    )
                  }

                  {
                    abilities.can('clear_storage', 'admin') && (
                      <li className={ ['sidenav-item'].join(' ') }>
                        <a className='sidenav-link' href='#' onClick={ clearLocalStorage }>
                          <FontAwesomeIcon icon={ faEraser } className='sidenav-icon' />

                          <div>Clear Storage</div>
                        </a>
                      </li>
                    )
                  }
                </ul>
              </li>
            </>
        }
        <li>
          <ul id='sideBarLogos' className=''>
            <li className=''>
              <img src={ sideBarLogoWsfr } alt='Southwick Associates Logo' />
            </li>
            <li className=''>
              <img src={ sideBarLogoCouncil } alt='Southwick Associates Logo' />
            </li>
            <li className=''>
              <img src={ sideBarLogoSa } alt='Southwick Associates Logo' />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

export default Sidenav
