import React from 'react'
import PropTypes from 'prop-types'
import DashboardSparkBarChart from './dashboard_sparkbar_chart'
import DashboardSparklineChart from './dashboard_sparkline_chart'
import Humanize from 'humanize-plus'

export default function DashboardRow (props) {
  const { chartData, metric, label, category, index, chartColors } = props

  const getValues = function (obj) {
    return Object.keys(obj).map(function (key) { return obj[key] })
  }

  const formatMetric = (number) => {
    if (metric === 'license_rate' || metric === 'churn_rate') {
      return Humanize.formatNumber(number, 1) + '%'
    } else {
      return Humanize.formatNumber(number, 1)
    }
  }

  if (props.metric === 'licensees' || props.metric === 'recruits' || props.category !== 'nonresident') {
    return (
      <tr>
        <td className='font-weight-bold'>{ Humanize.capitalize(label) }</td>
        <td>
          <DashboardSparkBarChart
            label={ Humanize.capitalize(label) }
            value1={ chartData.labels.region1.demographic_rates[category] }
            region1={ chartData.labels.region1.region }
            value2={ chartData.labels.region2.demographic_rates[category] }
            region2={ chartData.labels.region2.region }
            chartColors={ chartColors }
          />
        </td>
        <td>
          { (formatMetric(Humanize.formatNumber(chartData.labels.region1.demographic_rates[category], 1), 1) + '%').replace('%%', '%') }
          <br />
          { (formatMetric(Humanize.formatNumber(chartData.labels.region2.demographic_rates[category], 1), 1) + '%').replace('%%', '%') }
        </td>
        <td>
          <DashboardSparklineChart
            values1={ getValues(chartData.dataChanges.region1[category]) }
            region1={ chartData.labels.region1.region }
            values2={ getValues(chartData.dataChanges.region2[category]) }
            region2={ chartData.labels.region2.region }
            years={ chartData.years }
            chartColors={ chartColors }
          />
        </td>
        <td>
          { Humanize.formatNumber(chartData.dataChanges.region1[category][chartData.focusYear], 1) + '%' }
          <br />
          { Humanize.formatNumber(chartData.dataChanges.region2[category][chartData.focusYear], 1) + '%' }
        </td>
      </tr>
    )
  } else {
    return (
      <tr key={index}>
        <td className='font-weight-bold'>{ Humanize.capitalize(label) }</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    )
  }
}

DashboardRow.propTypes = {
  chartData: PropTypes.object,
  region: PropTypes.string,
  label: PropTypes.string,
  category: PropTypes.string,
  index: PropTypes.number,
  metric: PropTypes.string,
  chartColors: PropTypes.object
}
