export class Ability {
  constructor (permissions) {
    this.permissions = permissions
  }

  can (action, resource) {
    const permission = this.permissions.find((permission) => ['all', resource].includes(permission.subject))

    if (permission) {
      return permission.actions.includes('manage') || permission.actions.includes(action)
    } else {
      return false
    }
  }
}
