import { create, get, remove, update } from '../client'

const roleClient = {
  getAll: async () => {
    return (await get('/api/roles')).roles
  },

  get: async (roleId) => {
    return get(`/api/roles/${roleId}`)
  },

  create: async (params) => {
    return create('/api/roles', params)
  },

  update: async (roleId, params) => {
    return update(`/api/roles/${roleId}`, params)
  },

  remove: async (roleId) => {
    return remove(`/api/roles/${roleId}`)
  }
}

export { roleClient }
