import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { client } from '../../lib/client'
import I18n from '../../lib/i18n'
import SignInLogo from 'images/sign-in-logo.png'

const AuthChangePasswordView = (props) => {
  const { handleSubmit, register } = useForm()
  const { token } = useParams()

  const history = useHistory()

  const navigateToLoginPage = () => {
    history.push('/users/sign_in')
  }

  const onSubmit = async (params) => {
    try {
      await client.auth.changePassword(
        token,
        params.password,
        params.password_confirmation
      )

      toast.success(I18n.t('devise.passwords.updated_not_active'))

      navigateToLoginPage()
    } catch (error) {
      const errors = []

      if (error.errors.password) {
        error.errors.password.forEach((message) => errors.push(`Password ${message}.`))
      }

      if (error.errors.password_confirmation) {
        error.errors.password_confirmation.forEach((message) => errors.push(`Password Confirmation ${message}.`))
      }

      toast.error(errors.join(', '))
    }
  }

  return (
    <div className="authentication-wrapper authentication-1 px-4">
      <div className="authentication-inner py-5">

        <div className="d-flex justify-content-center align-items-center">
          <div className="ui-w-65">
            <div className="w-100 position-relative">
              <img src={ SignInLogo } />
            </div>
          </div>
        </div>

        <form className="my-5">
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name="password" ref={ register() } />
          </Form.Group>

          <Form.Group>
            <Form.Label>Password Confirmation</Form.Label>
            <Form.Control type="password" name="password_confirmation" ref={ register() } />
          </Form.Group>

          <div className="text-center">
            <Button type="submit" variant="primary" onClick={ handleSubmit(onSubmit) }>
              Change my password
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AuthChangePasswordView
