import Humanize from 'humanize-plus'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function UserFormView (props) {
  const { userId } = useParams()
  const history = useHistory()

  const [roles, setRoles] = useState([])
  const [providers, setProviders] = useState([])
  const [regions, setRegions] = useState([])
  const [user, setUser] = useState({})
  const [processing, setProcessing] = useState(true)

  const { handleSubmit, register, reset } = useForm()

  // Update form default values for edit action
  useEffect(() => {
    if (user.id) {
      user.region_ids = user.regions.map((r) => String(r.id))
      user.role_id = user.role.id

      reset(user)
    }
  }, [user])

  useEffect(() => {
    async function fetchData () {
      setRoles(await client.roles.getAll())
      setProviders(await client.providers.getAll())
      setRegions(await client.regions.getAll())

      if (isEditForm()) {
        setUser(await client.users.get(userId))
      }

      setProcessing(false)
    }

    fetchData()
  }, [])

  const isEditForm = () => !!userId

  const navigateToUserListPage = () => {
    history.push('/users')
  }

  const onSubmit = async (params) => {
    setProcessing(true)

    try {
      if (isEditForm()) {
        await client.users.update(userId, params)

        toast.success('User was updated successfully')
      } else {
        await client.users.create(params)

        toast.success('User was created successfully')
      }

      navigateToUserListPage()
    } catch (error) {
      setProcessing(false)

      toast.error(error.errors.join(', '))
    }
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToUserListPage }>Users</Breadcrumb.Item>

            {
              isEditForm() ? (
                <>
                  <Breadcrumb.Item>{ user.name }</Breadcrumb.Item>
                  <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </>
              ) : (
                <Breadcrumb.Item active>New</Breadcrumb.Item>
              )
            }
          </Breadcrumb>
        </div>
      </div>

      <Form>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                First Name

                <span className='ml-1 text-primary'>*</span>
              </Form.Label>

              <Form.Control
                type='text'
                name='first_name'
                ref={ register() } />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>
                Last Name

                <span className='ml-1 text-primary'>*</span>
              </Form.Label>

              <Form.Control
                type='text'
                name='last_name'
                ref={ register() } />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group>
          <Form.Label>
            Email

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control
            type='email'
            autoComplete='off'
            name='email'
            ref={ register() } />
        </Form.Group>

        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                Role

                <span className='ml-1 text-primary'>*</span>
              </Form.Label>

              <Form.Control as='select' name='role_id' ref={ register() }>
                <option value=''>No Role Selected</option>

                {
                  roles.map((role) => <option value={ role.id } key={ role.id }>{ Humanize.capitalize(role.name) }</option>)
                }
              </Form.Control>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>Provider</Form.Label>

              <Form.Control as='select' name='provider_id' ref={ register() }>
                <option value=''>Not a Provider Account</option>

                {
                  providers.map((provider) => <option value={ provider.id } key={ provider.id }>{ Humanize.capitalize(provider.name) }</option>)
                }
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                Password

                <span className='ml-1 text-primary'>*</span>
              </Form.Label>

              <Form.Control
                type='password'
                name='password'
                autocomplete='new-password'
                ref={ register() } />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>
                Password Confirmation

                <span className='ml-1 text-primary'>*</span>
              </Form.Label>

              <Form.Control
                type='password'
                name='password_confirmation'
                ref={ register() } />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Tabs activeKey={ 'regions' }>
              <Tab eventKey='regions' title='Regions' className='p-3'>
                <div className='row'>
                  {
                    regions.map((region, index) =>
                      <div key={ region.id } className='col col-2 mr-3'>
                        <Form.Check
                          name={ 'region_ids[]' }
                          id={ `user_region_${region.name}` }
                          type='checkbox'
                          inline
                          label={ region.name }
                          value={ region.id }
                          key={ region.name }
                          ref={ register() } />
                      </div>
                    )
                  }
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <div className='row'>
          <div className='col'>
            <div className='pt-3'>
              <Button variant='warning' type='submit' onClick={ handleSubmit(onSubmit) }>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

UserFormView.propTypes = {
  userId: PropTypes.number
}
