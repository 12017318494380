import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/pro-duotone-svg-icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function TokenListView () {
  const [tokens, setTokens] = useState([])
  const [processing, setProcessing] = useState(true)

  const history = useHistory()

  useEffect(() => {
    async function fetchData () {
      setTokens(await client.tokens.getAll())
      setProcessing(false)
    }

    fetchData()
  }, [])

  const navigateToTokenNewView = () => {
    history.push('/tokens/new')
  }

  const navigateToTokenShowView = (tokenId) => {
    history.push(`/tokens/${tokenId}`)
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-10'>
          <Breadcrumb>
            <Breadcrumb.Item active>Tokens</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className='col-2'>
          <Button variant='success' type='submit' className='float-right' onClick={ navigateToTokenNewView }>
            <FontAwesomeIcon icon={ faPlus } />
            &nbsp;New
          </Button>
        </div>
      </div>

      <table className='table'>
        <thead>
          <tr>
            <th>Description</th>
            <th>Created On</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {
            tokens.map((token) => (
              <tr key={ token.id }>
                <td>
                  { token.description }
                </td>

                <td>
                  <Moment date={ token.created_at } format='LLL' />
                </td>

                <td>
                  <Button
                    variant='danger'
                    className='float-left'
                    onClick={ () => navigateToTokenShowView(token.id) }>
                    <FontAwesomeIcon icon={ faTrash } />
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}
