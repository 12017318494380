import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faPlus } from '@fortawesome/pro-duotone-svg-icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function RegionListView () {
  const [regions, setRegions] = useState([])
  const [processing, setProcessing] = useState(true)

  const history = useHistory()

  useEffect(() => {
    async function fetchData () {
      setRegions(await client.regions.getAll())
      setProcessing(false)
    }

    fetchData()
  }, [])

  const navigateToRegionNewView = () => {
    history.push('/regions/new')
  }

  const navigateToRegionEditView = (regionId) => {
    history.push(`/regions/${regionId}/edit`)
  }

  const navigateToRegionShowView = (regionId) => {
    history.push(`/regions/${regionId}`)
  }

  const removeRegion = async (regionId) => {
    setProcessing(true)

    await client.regions.remove(regionId)

    setRegions(await client.regions.getAll())
    setProcessing(false)
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-10'>
          <Breadcrumb>
            <Breadcrumb.Item active>Regions</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className='col-2'>
          <Button variant='success' type='submit' className='float-right' onClick={ navigateToRegionNewView }>
            <FontAwesomeIcon icon={ faPlus } />
            &nbsp;New
          </Button>
        </div>
      </div>

      <table className='table'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Subdomain</th>
            <th>States</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {
            regions.map((region) => (
              <tr key={ region.id }>
                <td>
                  <a href='#' onClick={ () => navigateToRegionShowView(region.id) }>{ region.name }</a>
                </td>

                <td>
                  { region.subdomain }
                </td>

                <td>
                  { region.states.length }
                </td>

                <td>
                  <Button variant='primary' className='float-left mr-2' onClick={ () => navigateToRegionEditView(region.id) }>
                    <FontAwesomeIcon icon={ faEdit } />
                  </Button>

                  <Button
                    variant='danger'
                    className='float-left'
                    onClick={
                      () => {
                        if (window.confirm('Are you sure you wish to delete this region?')) { removeRegion(region.id) }
                      }
                    }>
                    <FontAwesomeIcon icon={ faTrash } />
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}
