import { create, get, update } from '../client'

const licenseClient = {
  getAll: async (stateId) => {
    return (await get(`/api/states/${stateId}/licenses`)).licenses
  },

  get: async (stateId, licenseId) => {
    return get(`/api/states/${stateId}/licenses/${licenseId}`)
  },

  create: async (stateId, params) => {
    return create(`/api/states/${stateId}/licenses`, params)
  },

  update: async (stateId, licenseId, params) => {
    return update(`/api/states/${stateId}/licenses/${licenseId}`, params)
  }
}

export { licenseClient }
