import React from 'react'
import footerLogo from 'images/footer-logo.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <nav className='layout-footer footer bg-footer-theme'>
      <div className='container-fluid d-flex flex-wrap justify-content-between container-p-x pb-3'>
        <div className='pt-3'>
          <img src={ footerLogo } /> ©
        </div>

        <div>
          <Link to='/about' className='footer-link pt-3'>
            About Us
          </Link>
          <Link to='/help' className='footer-link pt-3 ml-4'>
            Methodology
          </Link>
          <Link to='#' className='footer-link pt-3 ml-4'>
            Contact
          </Link>
          <Link to='/terms' className='footer-link pt-3 ml-4'>
            Terms &amp; Conditions
          </Link>
          <Link to='/privacy' className='footer-link pt-3 ml-4'>
            Privacy Policy
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Footer
