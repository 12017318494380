import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Form from 'react-bootstrap/Form'
import { useParams, useHistory } from 'react-router-dom'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function StateShowView (props) {
  const { stateId } = useParams()
  const history = useHistory()

  const [state, setState] = useState({})
  const [processing, setProcessing] = useState(true)

  useEffect(() => {
    async function fetchData () {
      setState(await client.states.get(stateId))
      setProcessing(false)
    }

    fetchData()
  }, [])

  const navigateToStateListPage = () => {
    history.push('/states')
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToStateListPage }>States</Breadcrumb.Item>
            <Breadcrumb.Item active>{ state.name }</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className='row'>
        <div className='col-1'>
          <strong>Abbreviation:</strong>
        </div>

        <div className='col-11'>
          { state.abbreviation }
        </div>
      </div>

      <div className='row'>
        <div className='col-1'>
          <strong>Name:</strong>
        </div>

        <div className='col-11'>
          { state.name }
        </div>
      </div>

      <div className='row'>
        <div className='col-1'>
          <strong>Status:</strong>
        </div>

        <div className='col-11'>
          { state.active ? 'Active' : 'Inactive' }
        </div>
      </div>

      <div className='row mt-3'>
        <div className='col-6'>
          <fieldset>
            <legend>Configuration</legend>

            <div className='row'>
              <div className='col-2'>
                <strong>Files Storage:</strong>
              </div>

              <div className='col-10'>
                { String(state.files_storage).toUpperCase() }
              </div>
            </div>

            <div className='row'>
              <div className='col-2'>
                <strong>Files Location:</strong>
              </div>

              <div className='col-10'>
                { state.files_location }
              </div>
            </div>

            <div className='row'>
              <div className='col-2'>
                <strong>Files Format:</strong>
              </div>

              <div className='col-10'>
                { String(state.files_format).toUpperCase() }
              </div>
            </div>
          </fieldset>
        </div>

        <div className='col-6'>
          <fieldset>
            <legend>License types</legend>

            <div className='row'>
              {
                (state.license_types || []).map((licenseType, index) =>
                  <div key={ licenseType.id } className='col col-2 mr-3'>
                    <Form.Check
                      inline
                      id={ `state_license_type_${licenseType.name}` }
                      type='checkbox'
                      label={ licenseType.name }
                      defaultChecked={ true } />
                  </div>
                )
              }
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  )
}

StateShowView.propTypes = {
  stateId: PropTypes.number
}
