import { Ability } from '../ability'
import React, { useContext } from 'react'

const initialContext = {
  user: {},
  abilities: new Ability([]),
  isAuthenticated: false,
  isLoggingOut: false,
  isLoading: true,
  isFailing: false,
  error: null,
  fecthLoggedUser: () => {},
  logout: () => {}
}

const AuthContext = React.createContext(initialContext)
const useAuth = () => useContext(AuthContext)

export { AuthContext, initialContext, useAuth }
