import { create, get, update } from '../client'

const populationClient = {
  getAllByYear: async (year) => {
    return (await get('/api/populations', { year })).populations
  },

  getAll: async () => {
    return (await get('/api/populations')).populations
  },

  get: async (populationId) => {
    return get(`/api/populations/${populationId}`)
  },

  create: async (params) => {
    return create('/api/populations', params)
  },

  update: async (populationId, params) => {
    return update(`/api/populations/${populationId}`, params)
  }
}

export { populationClient }
