import React, { useEffect, useState } from 'react'
import { Media, Collapse, Row, Col } from 'react-bootstrap'
import { groupBy, sortBy } from 'underscore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function FAQListView () {
  const [questions, setQuestions] = useState([])
  const [categories, setCategories] = useState([])
  const [expanded, setExpanded] = useState([])
  const [processing, setProcessing] = useState(true)

  const groupedQuestions = groupBy(sortBy(questions, 'sort_order'), 'faq_category_id')

  useEffect(() => {
    async function fetchData () {
      setCategories(await client.faq.categories.getAll())
      setQuestions(await client.faq.questions.getAll())
      setProcessing(false)
    }

    fetchData()
  }, [])

  const prevent = (event) => event.preventDefault()

  const toggle = (event, id) => {
    prevent(event)

    const newExpanded = [...expanded]

    if (newExpanded.indexOf(id) === -1) {
      newExpanded.push(id)
    } else {
      newExpanded.splice(newExpanded.indexOf(id), 1)
    }

    setExpanded(newExpanded)
  }

  const isExpanded = (id) => (expanded.indexOf(id) !== -1)

  return (
    <div>
      <Spinner visible={ processing } />

      <h3 className="text-center font-weight-bold py-3 mb-4">
        Frequently Asked Questions
      </h3>

      <hr className="container-m-nx border-light my-0" />

      <Row className="mt-4">
        <Col lg={4} xl={3}>
          <div>
            {
              categories.map((category) => (
                <a href="#d" key={ `category-${category.id}` } onClick={ prevent } className="media align-items-center bg-lighter text-body py-3 px-4">
                  <div className="ui-w-30 text-center text-xlarge">
                    <FontAwesomeIcon icon={ ['fad', category.icon] } />
                  </div>

                  <Media.Body className="ml-3">
                    { category.name }

                    <div className="small">{ category.description }</div>
                  </Media.Body>
                </a>
              ))
            }
          </div>
        </Col>

        <Col>
          {
            categories.map((category) => (
              <span key={ `category-${category.id}` }>
                <h4 className="media align-items-center my-4">
                  <div className="ui-w-60 text-center text-large">
                    <FontAwesomeIcon icon={ ['fad', category.icon] } />
                  </div>

                  <Media.Body className="ml-1">
                    { category.name }

                    <div className="text-tiny font-weight-light">{ category.description }</div>
                  </Media.Body>
                </h4>

                {
                  (groupedQuestions[category.id] || []).map((question) => (
                    <div className="theme-bg-white ui-bordered mb-2" key={ `question-${question.id}` }>
                      <a href="#toggle"
                         onClick={ (e) => toggle(e, `faq-${question.id}`) }
                         aria-expanded={ String(isExpanded(`faq-${question.id}`)) }
                         className="d-flex justify-content-between text-body py-3 px-4"
                         style={ { backgroundColor: (question.status === 'Preview' ? '#ddd' : 'white') } }
                      >
                        { question.question }

                        <span className="collapse-icon"></span>
                      </a>

                      <Collapse in={ isExpanded(`faq-${question.id}`) }>
                        <div>
                          <div className="px-4 pb-3" dangerouslySetInnerHTML={ { __html: question.answer } }></div>
                        </div>
                      </Collapse>
                    </div>
                  ))
                }

                <hr className="my-5" />
              </span>
            ))
          }
        </Col>
      </Row>
    </div>
  )
}
