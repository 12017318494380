
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons'
import React from 'react'

const BooleanIcon = (props) => {
  if (props.value) {
    return (
      <span style={ { color: 'green', fontSize: '30px' } }>
        <FontAwesomeIcon icon={ faCheckCircle } />
      </span>
    )
  } else {
    return (
      <span style={ { color: 'red', fontSize: '30px' } }>
        <FontAwesomeIcon icon={ faTimesCircle } />
      </span>
    )
  }
}

BooleanIcon.propTypes = {
  value: PropTypes.bool
}

export default BooleanIcon
