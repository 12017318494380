import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUser, faUserCircle, faSignIn } from '@fortawesome/pro-duotone-svg-icons'
import headerLogo from 'images/header-logo.png'
import React from 'react'

import { useAuth } from '../../lib/auth/context'

const Navbar = () => {
  const { isAuthenticated, logout, user } = useAuth()

  const signOut = async (event) => {
    event.preventDefault()

    await logout()

    window.location = '/'
  }

  return (
    <nav id='layout-navbar' className='layout-navbar navbar navbar-expand-lg align-items-lg-center bg-navbar-theme container-p-x'>
      <a href='/' className='navbar-brand app-brandd-lg-none py-0 mr-4'>
        <span className='app-brand-logo'>
          <img src={ headerLogo } />
        </span>
        <span className='app-brand-text font-weight-normal ml-2'>
        </span>
      </a>

      <div className='layout-sidenav-toggle navbar-nav d-lg-none align-items-lg-center mr-auto'>
        <a className='nav-item nav-link px-0 mr-lg-4' href='javacript:void(0)'>
          <span className='navbar-toggler-icon' />
        </a>
      </div>

      <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#layout-navbar-collapse'>
        <span className='navbar-toggler-icon' />
      </button>

      <div id='layout-navbar-collapse' className='navbar-collapse collapse'>
        <hr className='d-lg-none w-100 my-2' />
        <div className='navbar-nav align-items-lg-center'>&nbsp;</div>
        <div className='navbar-nav align-items-lg-center ml-auto'>
          <div className='nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1'>|</div>
          {
            isAuthenticated ? (
              <div className='nav-item dropdown'>
                <a className='nav-link dropdown-toggle' href='#' data-toggle='dropdown'>
                  <FontAwesomeIcon icon={ faUserCircle } />

                  <span className='d-inline-flex flex-lg-row-reverse align-items-center align-middle'>
                    <span className='px-1 mr-lg-2 ml-2 ml-lg-0 signInText'>
                      { user.email }
                    </span>
                  </span>
                </a>

                <div className='dropdown-menu dropdown-menu-right'>
                  <a href='/profile' className='dropdown-item'>
                    <FontAwesomeIcon icon={ faUser } /> My profile
                  </a>

                  <div className='dropdown-divider'></div>

                  <a className='dropdown-item' onClick={ signOut } href='#'>
                    <FontAwesomeIcon icon={ faSignOutAlt } /> Sign Out
                  </a>
                </div>
              </div>
            ) : (
              <a href='/users/sign_in' className='dropdown-item'>
                <FontAwesomeIcon icon={ faSignIn } />
                <span className='d-inline-flex flex-lg-row-reverse align-items-center align-middle'>
                  <span className='px-1 mr-lg-1 ml-1 ml-lg-0 signInText'>
                    Sign In
                  </span>
                </span>
              </a>
            )
          }
        </div>
      </div>
    </nav>
  )
}

export default Navbar
