import PropTypes from 'prop-types'
import React from 'react'
import { FormControl } from 'react-bootstrap'

export default function FormSelect (props) {
  const { choices, disabled, includeBlank, name, onUpdate, register, selected } = props

  const options = choices.map((choice) =>
    <option key={ choice.value } value={ choice.value }>{choice.text}</option>
  )

  if (includeBlank) {
    options.unshift(<option key='-1'></option>)
  }

  return (
    <div>
      <FormControl as='select'
        id={ name }
        name={ name }
        className={ 'form-control' }
        defaultValue={ selected }
        disabled={ disabled ? 'disabled' : '' }
        onChange={ (e) => { onUpdate && onUpdate(name, e.target.value) } }
        ref={ register && register() }
      >
        { options }
      </FormControl>
    </div>
  )
}

FormSelect.propTypes = {
  name: PropTypes.string,
  choices: PropTypes.array,
  includeBlank: PropTypes.bool,
  selected: PropTypes.string,
  disabled: PropTypes.bool,
  register: PropTypes.func,
  onUpdate: PropTypes.func
}
