import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Humanize from 'humanize-plus'

export default function DashboardTrendChart (props) {
  const { years, values, yoy, label, chartColor } = props

  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  })

  const chartOptions = {
    chart: {
      type: 'spline',
      height: 150
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: years
    },
    yAxis: {
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      startOnTick: false,
      endOnTick: false
    },
    tooltip: {
      hideDelay: 0,
      outside: true,
      shared: true,
      formatter: function () {
        let s = ''

        if (label === 'License Holders' || label === 'New Recruits') {
          $.each(this.points, function (index, point) {
            let value = ''
            value = '<b>' + Humanize.formatNumber(yoy[point.point.index + 1], 1) + '%'
            s += '<span style="color:' + point.color + '">\u25CF</span>&nbsp;'
            if (point.point.index > 0) {
              s += 'Change Over&nbsp;' + Number(point.key - 1) + ':&nbsp;' + value
            } else {
              s += point.key
            }
          })
        } else {
          $.each(this.points, function (index, point) {
            let value = ''
            value = '<b>' + Humanize.formatNumber(values[point.point.index], 1) + '%'
            s += '<span style="color:' + point.color + '">\u25CF</span>&nbsp;'
            s += Number(point.key) + '&nbsp;' + label + ':&nbsp;' + value
          })
        }

        return s
      }
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 3,
        pointWidth: 40,
        shadow: false,
        states: {
          hover: {
            lineWidth: 3
          }
        },
        marker: {
          radius: 4,
          states: {
            hover: {
              radius: 4
            }
          },
          fillOpacity: 0.25
        }
      }
    },
    series: [{ name: label, color: chartColor, data: values }]
  }
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  )
}

DashboardTrendChart.propTypes = {
  chartColor: PropTypes.string,
  years: PropTypes.array,
  values: PropTypes.array,
  yoy: PropTypes.array,
  label: PropTypes.string
}
