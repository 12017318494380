import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFile, faPlus } from '@fortawesome/pro-duotone-svg-icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import BooleanIcon from '../shared/icons/boolean'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function StateListView () {
  const [states, setStates] = useState([])
  const [processing, setProcessing] = useState(true)

  const history = useHistory()

  useEffect(() => {
    async function fetchData () {
      setStates(await client.states.getAll())
      setProcessing(false)
    }

    fetchData()
  }, [])

  const navigateToStateNewView = () => {
    history.push('/states/new')
  }

  const navigateToStateEditView = (stateId) => {
    history.push(`/states/${stateId}/edit`)
  }

  const navigateToStateShowView = (stateId) => {
    history.push(`/states/${stateId}`)
  }

  const navigateToStateFilesView = (stateId) => {
    history.push(`/states/${stateId}/datafiles`)
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-10'>
          <Breadcrumb>
            <Breadcrumb.Item active>States</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className='col-2'>
          <Button variant='success' type='submit' className='float-right' onClick={ navigateToStateNewView }>
            <FontAwesomeIcon icon={ faPlus } />
            &nbsp;New
          </Button>
        </div>
      </div>

      <table className='table'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Abbreviation</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {
            states.map((state) => (
              <tr key={ state.id }>
                <td>
                  <a href='#' onClick={ () => navigateToStateShowView(state.id) }>{ state.name }</a>
                </td>

                <td>
                  { state.abbreviation }
                </td>

                <td>
                  <BooleanIcon value={ state.active } />
                </td>

                <td>
                  <Button variant='primary' className='float-left mr-2' onClick={ () => navigateToStateEditView(state.id) }>
                    <FontAwesomeIcon icon={ faEdit } />
                  </Button>

                  <Button variant='primary' className='float-left' onClick={ () => navigateToStateFilesView(state.id) }>
                    <FontAwesomeIcon icon={ faFile } />
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}
