import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { matchPath } from 'react-router'
import { Route, Switch, useHistory } from 'react-router-dom'

import PrivacyView from './components/static/privacy'
import TermsView from './components/static/terms'
import AboutView from './components/static/about'
import MethodologyView from './components/static/methodology'
import WelcomeView from './components/static/welcome'

import AuthChangePasswordView from './components/auth/change_password'
import AuthConfirmationView from './components/auth/confirmation'
import AuthForgotPasswordView from './components/auth/forgot_password'
import AuthLoginView from './components/auth/login'
import AuthUnlockView from './components/auth/unlock'

import DatafileListView from './components/datafiles/list'

import FAQListView from './components/faq/index'
import FAQQuestionListView from './components/faq/questions/list'
import FAQQuestionFormView from './components/faq/questions/form'

import LicenseFormView from './components/licenses/form'

import LicenseTypeListView from './components/license_types/list'
import LicenseTypeFormView from './components/license_types/form'

import ProfileShowView from './components/profile/show'

import RegionListView from './components/regions/list'
import RegionFormView from './components/regions/form'
import RegionShowView from './components/regions/show'

import RoleListView from './components/roles/list'
import RoleFormView from './components/roles/form'
import RoleShowView from './components/roles/show'

import ProviderListView from './components/providers/list'
import ProviderFormView from './components/providers/form'
import ProviderShowView from './components/providers/show'

import PopulationListView from './components/populations/list'
import PopulationFormView from './components/populations/form'

import StateListView from './components/states/list'
import StateFormView from './components/states/form'
import StateShowView from './components/states/show'

import TokenFormView from './components/tokens/form'
import TokenListView from './components/tokens/list'
import TokenShowView from './components/tokens/show'

import VisualizationsDashboardView from './components/visualizations/dashboard'
import VisualizationsLicenseHoldersView from './components/visualizations/license_holders'
import VisualizationsPurchasesView from './components/visualizations/purchases'

import CouncilDashboardView from './components/council/dashboard'
import CouncilLicenseHoldersView from './components/council/license_holders'
import CouncilPurchasesView from './components/council/purchases'

import UserListView from './components/users/list'
import UserFormView from './components/users/form'

import Empty from './components/shared/empty'
import NotFoundView from './components/shared/not_found'

import { useAuth } from './lib/auth/context'

const withAuthenticationRequired = (Component) => {
  const { isAuthenticated, isFailing, isLoading, isLoggingOut } = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !isLoggingOut) {
      history.push(`/users/sign_in?goback=${history.location.pathname}`)
    }
  }, [isAuthenticated, isFailing, isLoading, isLoggingOut])

  return isAuthenticated ? <Component /> : <Empty />
}

const ProtectedRoute = (props) => {
  const { component } = props

  return (
    <Route { ...props } component={ () => withAuthenticationRequired(component) } />
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired
}

const switchRoutes = () => {
  return (
    <Switch>
      <Route exact path='/' component={ WelcomeView } />
      <Route exact path='/privacy' component={ PrivacyView } />
      <Route exact path='/terms' component={ TermsView } />
      <Route exact path='/about' component={ AboutView } />
      <Route exact path='/methodology' component={ MethodologyView } />
      <Route exact path='/help' component={ FAQListView } />

      <ProtectedRoute exact path='/faq/questions' component={ FAQQuestionListView } />
      <ProtectedRoute exact path='/faq/questions/new' component={ FAQQuestionFormView } />
      <ProtectedRoute exact path='/faq/questions/:questionId/edit' component={ FAQQuestionFormView } />

      <ProtectedRoute exact path="/states/:stateId/licenses/:licenseId/edit" component={ LicenseFormView } />

      <ProtectedRoute exact path='/license_types' component={ LicenseTypeListView } />
      <ProtectedRoute exact path='/license_types/new' component={ LicenseTypeFormView } />
      <ProtectedRoute exact path='/license_types/:licenseTypeId/edit' component={ LicenseTypeFormView } />

      <ProtectedRoute exact path='/profile' component={ ProfileShowView } />

      <ProtectedRoute exact path='/regions' component={ RegionListView } />
      <ProtectedRoute exact path='/regions/new' component={ RegionFormView } />
      <ProtectedRoute exact path='/regions/:regionId/edit' component={ RegionFormView } />
      <ProtectedRoute exact path='/regions/:regionId' component={ RegionShowView } />

      <ProtectedRoute exact path='/roles' component={ RoleListView } />
      <ProtectedRoute exact path='/roles/new' component={ RoleFormView } />
      <ProtectedRoute exact path='/roles/:roleId/edit' component={ RoleFormView } />
      <ProtectedRoute exact path='/roles/:roleId' component={ RoleShowView } />

      <ProtectedRoute exact path='/providers' component={ ProviderListView } />
      <ProtectedRoute exact path='/providers/new' component={ ProviderFormView } />
      <ProtectedRoute exact path='/providers/:providerId/edit' component={ ProviderFormView } />
      <ProtectedRoute exact path='/providers/:providerId' component={ ProviderShowView } />

      <ProtectedRoute exact path='/populations' component={ PopulationListView } />
      <ProtectedRoute exact path='/populations/:year' component={ PopulationFormView } />

      <ProtectedRoute exact path='/states' component={ StateListView } />
      <ProtectedRoute exact path='/states/new' component={ StateFormView } />
      <ProtectedRoute exact path='/states/:stateId/edit' component={ StateFormView } />
      <ProtectedRoute exact path='/states/:stateId' component={ StateShowView } />

      <ProtectedRoute exact path='/states/:stateId/datafiles' component={ DatafileListView } />

      <ProtectedRoute exact path='/tokens' component={ TokenListView } />
      <ProtectedRoute exact path='/tokens/new' component={ TokenFormView } />
      <ProtectedRoute exact path='/tokens/:tokenId' component={ TokenShowView } />

      <ProtectedRoute exact path="/users" component={ UserListView } />
      <ProtectedRoute exact path="/users/new" component={ UserFormView } />
      <ProtectedRoute exact path="/users/:userId/edit" component={ UserFormView } />

      <ProtectedRoute Route exact path='/visualizations/dashboard' component={ VisualizationsDashboardView } />
      <ProtectedRoute Route exact path='/visualizations/dashboard/:chartDataMd5' component={ VisualizationsDashboardView } />
      <ProtectedRoute Route exact path='/visualizations/license_holders' component={ VisualizationsLicenseHoldersView } />
      <ProtectedRoute Route exact path='/visualizations/license_holders/:chartDataMd5' component={ VisualizationsLicenseHoldersView } />
      <ProtectedRoute Route exact path='/visualizations/purchases' component={ VisualizationsPurchasesView } />
      <ProtectedRoute Route exact path='/visualizations/purchases/:chartDataMd5' component={ VisualizationsPurchasesView } />

      <Route Route exact path='/council/dashboard' component={ CouncilDashboardView } />
      <Route Route exact path='/council/license_holders' component={ CouncilLicenseHoldersView } />
      <Route Route exact path='/council/purchases' component={ CouncilPurchasesView } />

      <Route exact path='/users/sign_in' component={ AuthLoginView } />
      <Route exact path='/users/change_password/:token' component={ AuthChangePasswordView } />
      <Route exact path='/users/forgot_password' component={ AuthForgotPasswordView } />
      <Route exact path='/users/confirmation/:token' component={ AuthConfirmationView } />
      <Route exact path='/users/unlock/:token' component={ AuthUnlockView } />

      <Route path='*' component={ NotFoundView } />
    </Switch>
  )
}

const matchesRoute = (path) => {
  const routes = switchRoutes().props.children

  for (let i = 0; i < routes.length; i++) {
    if (routes[i].props.path !== '*') {
      const match = matchPath(path, routes[i].props.path)

      if (match && match.isExact) {
        return true
      }
    }
  }

  return false
}

const Routes = React.memo(switchRoutes)

Routes.displayName = 'Routes'

export { matchesRoute, Routes }
