import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default function DashboardSparklineChart (props) {
  const { values1, region1, values2, region2, years, chartColors } = props

  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  })

  const chartOptions = {
    chart: {
      type: 'spline',
      borderWidth: 0,
      margin: [0, 0, 0, 0],
      height: 40,
      width: 250
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      labels: {
        enabled: false
      },
      categories: years,
      title: {
        text: null
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: []
    },
    yAxis: {
      labels: {
        enabled: true,
        style: {
          fontSize: '9px'
        }
      },
      title: {
        text: null
      },
      startOnTick: false,
      endOnTick: false
    },
    legend: {
      enabled: false
    },
    tooltip: {
      hideDelay: 0,
      outside: true,
      shared: true
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 2,
        pointWidth: 40,
        shadow: false,
        states: {
          hover: {
            lineWidth: 2
          }
        },
        marker: {
          radius: 2,
          states: {
            hover: {
              radius: 2
            }
          },
          fillOpacity: 0.25
        }
      }
    },
    series: [
      { name: region1, color: chartColors.primary, data: values1 },
      { name: region2, color: chartColors.secondary, data: values2 }
    ]
  }

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  )
}

DashboardSparklineChart.propTypes = {
  values1: PropTypes.array,
  region1: PropTypes.string,
  values2: PropTypes.array,
  region2: PropTypes.string,
  years: PropTypes.array,
  chartColors: PropTypes.object
}
