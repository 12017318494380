/* eslint-disable import/first */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Include main file
require('../source/main')

import 'jquery'
import 'polyfills'
import 'sidenav'
import 'popper/popper'
import 'bootstrap'
import 'perfect-scrollbar'

import '../stylesheets/application'

// Import react App
import '../index'
