import 'core-js/features/promise'
import 'core-js/features/array'
import 'core-js/features/string'
import 'core-js/features/object'
import 'core-js/features/set'
import 'core-js/features/map'
import 'core-js/features/weak-map'
import 'core-js/features/symbol'
import 'core-js/es/number/is-nan'
import 'core-js/es/math/trunc'
import 'url-polyfill'
import 'custom-event-polyfill'
