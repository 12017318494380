import React, { useEffect, useReducer } from 'react'
import { useCookies } from 'react-cookie'
import { withRouter } from 'react-router-dom'
import { ERROR, GUEST, INITIALISED, LOGIN, LOGOUT } from './actions'
import { AuthContext, initialContext } from './context'
import { reducer } from './reducer'
import { client } from '../client'

const AuthProvider = withRouter(
  (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']) // eslint-disable-line no-unused-vars
    const [state, dispatch] = useReducer(reducer, initialContext)

    initialContext.logout = async () => {
      await client.auth.logout()

      removeCookie('bearer', { path: '/' })
      removeCookie('user.id', { path: '/' })

      dispatch({ type: LOGOUT })
    }

    initialContext.fetchLoggedUser = async () => {
      const user = await client.auth.me()

      dispatch({ type: INITIALISED, user })
    }

    useEffect(() => {
      (async () => {
        try {
          dispatch({ type: LOGIN })

          if (cookies.bearer) {
            const user = await client.auth.me()

            dispatch({ type: INITIALISED, user })
          } else {
            dispatch({ type: GUEST })
          }
        } catch (error) {
          removeCookie('bearer', { path: '/' })
          removeCookie('user.id', { path: '/' })

          dispatch({ type: ERROR, error })
        }
      })()
    }, [])

    return (
      <AuthContext.Provider value={ state }>
        { props.children }
      </AuthContext.Provider>
    )
  }
)

export { AuthProvider }
