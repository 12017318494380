import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Alert } from 'react-bootstrap'
import { useCookies } from 'react-cookie'

export default function DataNotice (props) {
  const { ignoreCookie } = props

  const [cookies, setCookie] = useCookies(['notice'])

  const [show, setShow] = useState(ignoreCookie === 'true' || cookies.notice !== 'true')

  const handleClose = () => setShow(false)

  setCookie('notice', 'true', { path: '/', maxAge: 43200 })

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header className='bg-primary text-white'>
          <Modal.Title>A Note About R3Dashboard.org Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant='info' className='text-center'>
            <span className='strong'>
              The information provided by this service helps characterize license buyers and trends over time.
            </span>
          </Alert>
          <hr></hr>
          <p>
            <span className='strong italics'>No two states&apos; license structures are the same.</span>
            To provide comparable results across states, some licenses are omitted.
            In addition, the methods used to summarize sales may differ from those used by individual states.
          </p>
          <p>
            The results provided by this service&nbsp;
            <span className='strong'>are not a precise accounting of actual sales or participation</span>,
            and will differ from the official, certified sales determined by each state.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleClose}>
            I Understand
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

DataNotice.propTypes = {
  ignoreCookie: PropTypes.string
}
