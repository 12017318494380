import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function ProviderFormView (props) {
  const { providerId } = useParams()
  const history = useHistory()

  const [provider, setProvider] = useState({})
  const [processing, setProcessing] = useState(true)

  const { handleSubmit, register, reset } = useForm()

  // Update form default values for edit action
  useEffect(() => {
    if (provider.id) {
      reset(provider)
    }
  }, [provider])

  useEffect(() => {
    async function fetchData () {
      if (isEditForm()) {
        setProvider(await client.providers.get(providerId))
      }

      setProcessing(false)
    }

    fetchData()
  }, [])

  const isEditForm = () => !!providerId

  const navigateToProviderListPage = () => {
    history.push('/providers')
  }

  const navigateToProviderPage = () => {
    history.push(`/providers/${providerId}`)
  }

  const onSubmit = async (params) => {
    setProcessing(true)

    try {
      if (isEditForm()) {
        await client.providers.update(providerId, params)

        toast.success('Provider was updated successfully')
      } else {
        await client.providers.create(params)

        toast.success('Provider was created successfully')
      }

      navigateToProviderListPage()
    } catch (error) {
      setProcessing(false)

      toast.error(error.errors.join(', '))
    }
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToProviderListPage }>Providers</Breadcrumb.Item>

            {
              isEditForm() ? (
                <>
                  <Breadcrumb.Item onClick={ navigateToProviderPage }>
                    { provider.name }
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </>
              ) : (
                <Breadcrumb.Item active>New</Breadcrumb.Item>
              )
            }
          </Breadcrumb>
        </div>
      </div>

      <Form>
        <Form.Group>
          <Form.Label>
            Name

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control
            type='text'
            name='name'
            ref={ register } />
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Abbreviation

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control
            type='text'
            name='abbreviation'
            ref={ register } />
        </Form.Group>

        <div className='row'>
          <div className='col'>
            <div className='pt-3'>
              <Button variant='warning' type='submit' onClick={ handleSubmit(onSubmit) }>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

ProviderFormView.propTypes = {
  providerId: PropTypes.number
}
