import React from 'react'
import { Link } from 'react-router-dom'

export default function Disclaimer () {
  return (
    <div className='row'>
      <div className='col pr-1'>
        <div className='alert alert-dark-warning'>
          By collecting data daily,
          the process used in this dashboard to calculate license sales
          is fundamentally different than the process used to determine
          state certified license numbers.
          These results are intended to provide trend information,
          not specific counts of licenses in effect,
          and will vary from certified counts.
          Additional information on the methodology used is available
          <Link to='/help' className=''>
            &nbsp;here.
          </Link>
        </div>
      </div>
    </div>
  )
}
