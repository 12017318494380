import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/pro-duotone-svg-icons'
import AsaLogo from 'images/about-logo-asa.png'
import AtaLogo from 'images/about-logo-ata.png'
import RbffLogo from 'images/about-logo-rbff.png'
import NssfLogo from 'images/about-logo-nssf.png'
import AfwaLogo from 'images/about-logo-afwa.png'
import CahssLogo from 'images/about-logo-cahss.png'
import DwLogo from 'images/about-logo-dw.png'
import NwtfLogo from 'images/about-logo-nwtf.png'
import NmmaLogo from 'images/about-logo-nmma.png'
import PfLogo from 'images/about-logo-pf.png'
import PragLogo from 'images/about-logo-prag.png'
import SwLogo from 'images/about-logo-sw.png'
import WsfrLogo from 'images/about-logo-wsfr.png'

const AboutView = () => {
  const sponsorsData = [
    {
      logo: WsfrLogo,
      name: 'Wildlife & Sport Fish Restoration',
      abbreviation: 'WSFR',
      tagline: 'Conserving Wildlife and Habitats and Connecting People with Nature',
      url: 'https://www.fws.gov/program/wildlife-and-sport-fish-restoration'
    },
    {
      logo: CahssLogo,
      name: 'Council to Advance the Hunting and Shooting Sports',
      abbreviation: 'CAHSS',
      tagline: '',
      url: 'https://cahss.org'
    },
    {
      logo: AsaLogo,
      name: 'American Sportfishing Association',
      abbreviation: 'ASA',
      tagline: "Leading the Way for Sportfishing's Future",
      url: 'https://asafishing.org'
    },
    {
      logo: AtaLogo,
      name: 'Archery Trade Association',
      abbreviation: 'ATA',
      tagline: '',
      url: 'https://archerytrade.org'
    },
    {
      logo: RbffLogo,
      name: 'Recreational Boating and Fishing Foundation',
      abbreviation: 'RBFF',
      tagline: '',
      url: 'https://www.takemefishing.org'
    },
    {
      logo: NssfLogo,
      name: 'National Shooting Sports Foundation',
      abbreviation: 'NSSF',
      tagline: 'The Firearm Industry Trade Association',
      url: 'https://www.nssf.org'
    },
    {
      logo: AfwaLogo,
      name: 'AFWA Technology and Data Committee',
      abbreviation: 'AFWA',
      tagline: 'The Voice of Fish & Wildlife Agencies',
      url: 'https://www.fishwildlife.org'
    },
    {
      logo: DwLogo,
      name: 'Delta Waterfowl',
      abbreviation: 'DW',
      tagline: '',
      url: 'https://deltawaterfowl.org'
    },
    {
      logo: NwtfLogo,
      name: 'National Wildlife Turkey Federation',
      abbreviation: 'NWTF',
      tagline: 'Conserve. Hunt. Share.',
      url: 'https://www.nwtf.org'
    },
    {
      logo: NmmaLogo,
      name: 'National Marine Manufacturers Association',
      abbreviation: 'NMMA',
      tagline: '',
      url: 'https://www.nmma.org'
    },
    {
      logo: PfLogo,
      name: 'Pheasants Forever',
      abbreviation: 'PF',
      tagline: 'The Habitat Organization',
      url: 'https://www.pheasantsforever.org'
    },
    {
      logo: SwLogo,
      name: 'Southwick Associates',
      abbreviation: 'SW',
      tagline: 'Fish & Wildlife Economics & Statistics',
      url: 'https://www.southwickassociates.com'
    }
  ]

  return (
    <div>
      <h4 className='font-weight-bold'>About R3Dashboard</h4>
      <Card className='mb-4'>
        <Card.Body>
          <p className='mb-4'>
            This tool has been constructed to provide insight regional and national trends in hunting and fishing license purchases and is designed to inform R3 efforts and collaboration between stakeholders.
            Visualizations of the data are provided to the public free of charge and break down license purchases into sales, active participants, and trends over time.
            Please take some time to review the <Link to='/methodology'>Methodology page</Link> to learn about how the dashboard is constructed, as well as the interpretation page which highlights best practices in using the data.
          </p>
          <p className='mb-4'>
            This project has been made possible through the Multistate Conservation Grant Program
            (<a href='https://www.fishwildlife.org/afwa-informs/multi-state-conservation-grants-program' target='_blank' rel='noreferrer'>MSCGP</a>) grants,
            and support from the following groups:
          </p>
          </Card.Body>
      </Card>
      <Row className='contacts-row-view'>
        {sponsorsData.map(contact =>
          <Col xs={12} sm={6} xl={4} key={contact.abbreviation} className='contacts-col'>

            <Card className='mb-4'>
              <Card.Body>
                <div className='contact-content'>
                  <img src={ contact.logo } className='contact-content-img mx-auto d-block mb-2' alt='Contact' />
                  <div className='contact-content-about text-center'>
                    <h5 className='contact-content-name mb-1'><a href={contact.url} className='text-body' target='_blank' rel='noreferrer'>{contact.name}</a></h5>
                    <div className='contact-content-user text-muted small mb-2'>{contact.tagline}&nbsp;</div>
                    <hr className='border-light' />
                    <div>
                      <a href={contact.url} className='text-secondary' target='_blank' rel='noreferrer'><span className='ion ion-md-mail'></span>{contact.url} <FontAwesomeIcon icon={ faExternalLinkAlt } /></a> &nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>

          </Col>

        )}
      </Row>
      <hr />
      <Row className='contacts-row-view mt-4 mb-4'>
        <Col xs={12} sm={6} md={4} key='PRAG' className='contacts-col'>
          <Card className='mt-2 mb-4'>
            <Card.Body>
              <p className='mb-2 text-justify'>
                A special thanks goes to Pragmatim Consulting Services, who developed the interface, data transfer system, and visualizations for this project.
              </p>
              <hr />
              <div className='contact-content'>
                <img src={ PragLogo } className='contact-content-img mx-auto d-block mb-2' alt='Contact' />
                <div className='contact-content-about text-center'>
                  <hr className='border-light' />
                  <div>
                    <a href='https://www.pragmatim.com' className='text-secondary' target='_blank' rel='noreferrer'><span className='ion ion-md-mail'></span>https://www.pragmatim.com <FontAwesomeIcon icon={ faExternalLinkAlt } /></a> &nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>

        </Col>
      </Row>
    </div>
  )
}

export default AboutView
