import React from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Humanize from 'humanize-plus'

export default function DashboardSparkBarChart (props) {
  const { label, value1, region1, value2, region2, chartColors } = props

  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  })

  const chartOptions = {
    chart: {
      type: 'bar',
      borderWidth: 0,
      margin: [0, 0, 0, 0],
      height: 40,
      style: {
        overflow: 'visible'
      }
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: []
    },
    yAxis: {
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      max: 100,
      min: 0,
      startOnTick: false,
      endOnTick: false,
      tickPositions: [0]
    },
    legend: {
      enabled: false
    },
    tooltip: {
      hideDelay: 0,
      outside: true,
      shared: true,
      formatter: function () {
        let s = label

        $.each(this.points, function (i, point) {
          s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + point.y + '%</b>'
        })

        return s
      }
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 1,
        pointWidth: 20,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 2
            }
          },
          fillOpacity: 0.25
        },
        column: {
          negativeColor4: '#910000',
          borderColor: 'silver'
        }
      }
    },
    series: [
      { name: region1, color: chartColors.primary, groupPadding: 0, pointPadding: 0, data: [{ name: Humanize.capitalize(label), y: value1 }] },
      { name: region2, color: chartColors.secondary, groupPadding: 0, pointPadding: 0, data: [{ name: Humanize.capitalize(label), y: value2 }] }
    ]
  }

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  )
}

DashboardSparkBarChart.propTypes = {
  label: PropTypes.string,
  region1: PropTypes.string,
  value1: PropTypes.number,
  region2: PropTypes.string,
  value2: PropTypes.number,
  max: PropTypes.number,
  chartColors: PropTypes.object
}
