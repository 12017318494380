import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Modal as BootstrapModal, Button } from 'react-bootstrap'
import { useIsMountedRef } from '../../lib/hooks'

const Modal = (props) => {
  const { Body, Footer, Header, Title } = BootstrapModal
  const { size, title, visible } = props
  const isMountedRef = useIsMountedRef()

  const [opened, setOpened] = useState(visible)

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        if (isMountedRef.current) {
          setOpened(false)
        }
      }, 150)
    } else {
      setOpened(true)
    }
  }, [visible])

  const onClose = () => {
    setOpened(false)

    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <BootstrapModal className='modal-top' show={ opened } size={ size } onHide={ onClose }>
      <Header className='bg-primary  text-white' closeButton>
        <Title as='h5'>{ title }</Title>
      </Header>

      <Body>{ props.children }</Body>

      <Footer className='bg-light'>
        <Button variant='default' onClick={ onClose }>Close</Button>
      </Footer>
    </BootstrapModal>
  )
}

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  size: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired
}

export default Modal
