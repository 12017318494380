import { get } from '../../client'

const faqCategoryClient = {
  getAll: async () => {
    return (await get('/api/faq/categories')).categories
  },

  get: async (categoryId) => {
    return get(`/api/faq/categories/${categoryId}`)
  }
}

export { faqCategoryClient }
