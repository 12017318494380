import { Ability } from '../ability'
import { ERROR, GUEST, INITIALISED, LOGIN, LOGOUT } from './actions'

/**
 * Handles how that state changes in the `useAuth` hook.
 */
export const reducer = (state, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false
      }
    case INITIALISED:
      return {
        ...state,
        user: action.user,
        abilities: new Ability(action.user.permissions),
        isLoading: false,
        isAuthenticated: true
      }
    case GUEST:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false
      }
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        isLoggingOut: true,
        isFailing: false,
        user: null,
        abilities: new Ability([])
      }
    case ERROR:
      return {
        ...state,
        isLoading: false,
        isFailing: true,
        error: action.error
      }
  }
}
