import { get } from '../client'

const councilClient = {
  getDashboard: async (filters) => {
    return (await get('/api/council/dashboard', filters))
  },

  getLicenseHolders: async (filters) => {
    return (await get('/api/council/license_holders', filters))
  },

  getPurchases: async (filters) => {
    return (await get('/api/council/purchases', filters))
  }
}

export { councilClient }
