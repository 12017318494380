import { create, get, update } from '../../client'

const faqQuestionClient = {
  getAll: async (params) => {
    return (await get('/api/faq/questions', params)).questions
  },

  get: async (questionId) => {
    return get(`/api/faq/questions/${questionId}`)
  },

  create: async (params) => {
    return create('/api/faq/questions', params)
  },

  update: async (questionId, params) => {
    return update(`/api/faq/questions/${questionId}`, params)
  }
}

export { faqQuestionClient }
