import React, { useEffect, useState, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Empty from '../shared/empty'
import DatePicker from 'react-datepicker'
import FormSelect from '../shared/form_select'
import { client } from '../../lib/client'
import Spinner from '../shared/spinner'
import moment from 'moment'
import Humanize from 'humanize-plus'
import { FormGroup } from 'react-bootstrap'
import { useLocalStorage } from '../../lib/hooks'

export default function CouncilLicenseHoldersView (props) {
  const [filters, setFilters] = useLocalStorage('rtFilters', { region_id: 1, from_date: moment().subtract(1, 'month').toDate(), to_date: moment().subtract(1, 'day').toDate(), license_type: 'all', statistic: 'participant', period: 'daily', residency: 'all', gender: 'all', age_range: 'all' })
  const [chartData, setChartData] = useState({})
  const [processing, setProcessing] = useState(true)

  useEffect(() => {
    async function fetchData () {
      setProcessing(true)

      const response = await client.council.getLicenseHolders(filters)

      const data = {
        regionId: response.region_id,
        header: response.labels.header,
        subHeader: response.labels.sub_header,
        xAxis: response.labels.x_axis,
        yAxis: response.labels.y_axis,
        regionsOptions: response.selectors.regions,
        licenseTypeOptions: response.selectors.license_types,
        statisticOptions: response.selectors.statistics,
        residencyOptions: response.selectors.residencies,
        genderOptions: response.selectors.genders,
        ageRangeOptions: response.selectors.age_ranges,
        periodOptions: response.selectors.periods,
        datasets: response.datasets,
        maxDays: response.max_days,
        xLabelStep: response.x_label_step,
        showMarkers: response.show_markers,
        edges: response.edges
      }

      setProcessing(false)
      setChartData(data)
    }

    fetchData()
  }, [filters])

  const handleFilterChange = function (name, value) {
    setFilters({ ...filters, [name]: value })
  }

  const fromDateRef = useRef()

  const handleFromDateChange = function (date) {
    if (date !== null) {
      const fromDate = moment(date, ['MM/DD/YYYY', 'MM-DD-YYYY'], true)
      if (fromDate !== 'Invalid date' && typeof fromDate !== 'undefined') {
        setFilters({ ...filters, from_date: fromDate.toDate() })
        fromDateRef.current.setOpen(false)
      }
    }
  }

  const toDateRef = useRef()

  const handleToDateChange = function (date) {
    if (date !== null) {
      const toDate = moment(date, ['MM/DD/YYYY', 'MM-DD-YYYY'], true)
      if (toDate !== 'Invalid date' && typeof toDate !== 'undefined') {
        setFilters({ ...filters, to_date: toDate.toDate() })
        toDateRef.current.setOpen(false)
      }
    }
  }

  const onKeyDown = (e) => {
    if (e.key === 'Tab') {
      fromDateRef.current.setOpen(false)
      toDateRef.current.setOpen(false)
    }
  }

  const maxDate = function () {
    const currentYear = new Date().getFullYear()
    const lastDay = new Date(currentYear, 11, 31)
    return lastDay
  }

  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  })

  const chartOptions = {
    chart: {
      type: 'area'
    },
    title: {
      text: chartData.header
    },
    subtitle: {
      text: chartData.subHeader
    },
    credits: {
      enabled: false
    },
    xAxis: {
      crosshair: true,
      categories: chartData.xAxis,
      labels: {
        step: chartData.xLabelStep,
        rotation: -45
      }
    },
    yAxis: {
      min: typeof chartData.edges === 'undefined' ? 0 : chartData.edges.min,
      title: {
        text: chartData.yAxis
      },
      labels: {
        formatter: function () {
          return Humanize.formatNumber(this.value, 0)
        }
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
      formatter: function () {
        let s = ''

        $.each(this.points, function (i, point) {
          s += '<span style="color:' + point.color + '">\u25CF</span> ' + point.key + ': <b>' + Humanize.formatNumber(point.y, 0) + '</b><br />'
        })

        return s
      }
    },
    plotOptions: {
      series: {
        grouping: false,
        borderWidth: 0,
        marker: {
          enabled: chartData.showMarkers
        }
      }
    },
    series: chartData.datasets
  }

  if (!Object.keys(chartData).length) { return <Empty /> }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row'>
        <div className='col pr-1'>
          <h4 className='font-weight-bold py-1 mb-1'>
            <OverlayTrigger placement='bottom' overlay={ <Tooltip>{ 'A License Holder represents an individual who holds one or more licenses granting primary privileges that are valid on at least one day during the applicable time period and for the applicable sport.' }</Tooltip> }>
              <span>License Holders</span>
            </OverlayTrigger>

            <div className='text-muted text-tiny mt-1'>
              <span className='font-weight-normal'>
                <small>Today is { moment().format('MMMM D, YYYY') }</small>
              </span>
            </div>
          </h4>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <div className='card mb-1'>
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  <OverlayTrigger overlay={ <Tooltip>{ 'A Region represents a state or a collection of states.' }</Tooltip> }>
                    <FormGroup>
                      <label>Region</label>
                      <FormSelect
                        name={ 'region_id' }
                        choices={ chartData.regionsOptions }
                        selected={ chartData.regionId }
                        onUpdate={ handleFilterChange }
                      />
                    </FormGroup>
                  </OverlayTrigger>
                </div>

                <div className='col'>
                  <FormGroup>
                    <label>Start On</label>
                    <DatePicker className="form-control"
                      name={ 'from_date' }
                      selected={moment(filters.from_date).toDate()}
                      onChange={(date) => handleFromDateChange(date)}
                      strictParsing
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      minDate={new Date('2011-01-01')}
                      maxDate={maxDate()}
                      ref={fromDateRef}
                      onKeyDown={onKeyDown}
                      popperPlacement="bottom-start"
                    />
                  </FormGroup>
                </div>

                <div className='col'>
                  <FormGroup>
                    <label>End On</label>
                    <DatePicker className="form-control"
                      name={ 'to-date' }
                      selected={moment(filters.to_date).toDate()}
                      onChange={(date) => handleToDateChange(date)}
                      strictParsing
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      minDate={new Date('2011-01-01')}
                      maxDate={maxDate()}
                      ref={toDateRef}
                      onKeyDown={onKeyDown}
                      popperPlacement="bottom-start"
                    />
                  </FormGroup>
                </div>

                <div className='col'>
                  <FormGroup>
                    <label>Period</label>
                    <FormSelect
                      name={ 'period' }
                      choices={ chartData.periodOptions }
                      selected={ filters.period }
                      onUpdate={ handleFilterChange }
                    />
                  </FormGroup>
                </div>
              </div>

              <div className='row'>
                <div className='col'>
                  <OverlayTrigger overlay={ <Tooltip>{ 'Sport is a licensed activity, such as Hunting or Fishing.' }</Tooltip> }>
                    <FormGroup>
                      <label>Sport</label>
                      <FormSelect
                        name='license_type'
                        choices={ chartData.licenseTypeOptions }
                        selected={ filters.license_type }
                        onUpdate={ handleFilterChange }
                      />
                    </FormGroup>
                  </OverlayTrigger>
                </div>

                <div className='col'>
                  <FormGroup>
                    <label>Residency</label>
                    <FormSelect
                      name='residency'
                      choices={ chartData.residencyOptions }
                      selected={ filters.residency }
                      onUpdate={ handleFilterChange }
                    />
                  </FormGroup>
                </div>

                <div className='col'>
                  <FormGroup>
                    <label>Gender</label>
                    <FormSelect
                      name='gender'
                      choices={ chartData.genderOptions }
                      selected={ filters.gender }
                      onUpdate={ handleFilterChange }
                    />
                  </FormGroup>
                </div>

                <div className='col'>
                  <FormGroup>
                    <label>Age Range</label>
                    <FormSelect
                      name='age_range'
                      choices={ chartData.ageRangeOptions }
                      selected={ filters.age_range }
                      onUpdate={ handleFilterChange }
                    />
                  </FormGroup>
                </div>
              </div>
              <div className='font-weight-normal'>
                <small>
                  <span className='strong'>*</span>
                  Data shown for multi-state regions such as
                  <span className='strong'> National </span>
                  and
                  <span className='strong'> SEAFWA </span>
                  presents summarized data for only those states participating in the Dashboard.</small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <div className='card mb-4'>
            <div className='card-body'>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
