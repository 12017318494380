import { get, create } from '../client'

const datafileClient = {
  getAllByState: async (stateId) => {
    return (await get(`/api/states/${stateId}/datafiles`)).datafiles
  },

  confirm: async (datafileId) => {
    return create(`/api/datafiles/${datafileId}/confirm`)
  }
}

export { datafileClient }
