import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'
import I18n from '../../lib/i18n'

export default function RoleFormView (props) {
  const { roleId } = useParams()
  const history = useHistory()

  const [abilities, setAbilities] = useState([])
  const [role, setRole] = useState({})
  const [processing, setProcessing] = useState(true)

  const { handleSubmit, register, reset } = useForm()

  // Update form default values for edit action
  useEffect(() => {
    if (Object.keys(role).length) { reset(addRoleNestedAttributes(role)) }
  }, [role])

  useEffect(() => {
    async function fetchData () {
      setAbilities(await client.abilities.getAll())

      if (isEditForm()) {
        setRole(await client.roles.get(roleId))
      }

      setProcessing(false)
    }

    fetchData()
  }, [])

  const isEditForm = () => !!roleId

  const addRoleNestedAttributes = (role) => {
    role.permissions_attributes = []

    abilities.forEach((ability, index) => {
      const permission = role.permissions.find((p) => p.ability === ability.subject_name)

      if (permission) {
        // [HACK] use-react-form does not work well when field name ends with [] to make it an array
        //   For the use case where an ability has only 1 action, use-react-form does not return
        //   an array, returns a string
        if (!permission.actions.length) { permission.actions = false }

        role.permissions_attributes[index] = permission
      }
    })

    return role
  }

  const transformAllPermissionActionsToArray = (params) => {
    params.permissions_attributes.forEach((permission) => {
      // [HACK] use-react-form does not work well when field name ends with [] to make it an array
      //   For the use case where an ability has only 1 action, use-react-form does not return
      //   an array, returns a string
      if (!(permission.actions instanceof Array)) {
        if (!permission.actions) {
          permission.actions = []
        } else {
          permission.actions = [permission.actions]
        }
      }
    })

    return params
  }

  const navigateToRoleListPage = () => {
    history.push('/roles')
  }

  const navigateToRolePage = () => {
    history.push(`/roles/${roleId}`)
  }

  const onSubmit = async (params) => {
    setProcessing(true)

    try {
      params = transformAllPermissionActionsToArray(params)

      if (isEditForm()) {
        await client.roles.update(roleId, params)

        toast.success('Role was updated successfully')
      } else {
        await client.roles.create(params)

        toast.success('Role was created successfully')
      }

      navigateToRoleListPage()
    } catch (error) {
      setProcessing(false)

      toast.error(error.errors.join(', '))
    }
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToRoleListPage }>Roles</Breadcrumb.Item>

            {
              isEditForm() ? (
                <>
                  <Breadcrumb.Item onClick={ navigateToRolePage }>
                    { role.name }
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </>
              ) : (
                <Breadcrumb.Item active>New</Breadcrumb.Item>
              )
            }
          </Breadcrumb>
        </div>
      </div>

      <Form>
        <Form.Group>
          <Form.Label>
            Name

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control
            type='text'
            name='name'
            ref={ register() } />
        </Form.Group>

        {
          abilities.map((ability, index) =>
            <div className='mb-3' key={ ability.subject_name }>
              <h6>{ ability.subject_name }</h6>

              {
                role.id &&
                  <Form.Control
                    type='hidden'
                    name={ `permissions_attributes[${index}].id` }
                    ref={ register } />
              }

              <Form.Control
                type='hidden'
                name={ `permissions_attributes[${index}].ability` }
                ref={ register }
                value={ ability.subject_name } />

              <hr />

              <div className='row'>
                {
                  ability.actions.map((action) =>
                    <div key={ action } className='col col-2 mr-3'>
                      <Form.Check
                        name={ `permissions_attributes[${index}].actions[]` }
                        type='checkbox'
                        inline
                        label={ action }
                        value={ action }
                        key={ action }
                        ref={ register } />

                      <br />

                      <i className='text-secondary'>{ I18n.t(`abilities.${ability.subject_name.toLowerCase()}.${action}`) }</i>
                    </div>
                  )
                }
              </div>
            </div>
          )
        }

        <div className='row'>
          <div className='col'>
            <div className='pt-3'>
              <Button variant='warning' type='submit' onClick={ handleSubmit(onSubmit) }>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

RoleFormView.propTypes = {
  roleId: PropTypes.number
}
