import { create, get } from '../client'

const chartClient = {
  get: async (chartDataMd5) => {
    return get(`/api/charts/${chartDataMd5}`)
  },

  create: async (params) => {
    return create('/api/charts', params)
  }
}

export { chartClient }
