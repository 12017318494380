import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import BootstrapSpinner from 'react-bootstrap/Spinner'
import Empty from './empty'
import { useIsMountedRef } from '../../lib/hooks'

const Spinner = (props) => {
  const { visible } = props

  const [loading, setLoading] = useState(visible)

  const isMountedRef = useIsMountedRef()

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        if (isMountedRef.current) {
          setLoading(false)
        }
      }, 300)
    } else {
      setLoading(true)
    }
  }, [visible])

  if (!loading) { return <Empty /> }

  return (
    <div className="spinner bg-secondary">
      <BootstrapSpinner animation="border" className="spinner__box__icon" />
    </div>
  )
}

Spinner.propTypes = {
  visible: PropTypes.bool.isRequired
}

export default Spinner
