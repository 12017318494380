import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { client } from '../../lib/client'
import I18n from '../../lib/i18n'
import SignInLogo from 'images/sign-in-logo.png'

const AuthForgotPasswordView = (props) => {
  const { handleSubmit, register } = useForm()

  const history = useHistory()

  const navigateToLoginPage = () => {
    history.push('/users/sign_in')
  }

  const onSubmit = async (params) => {
    try {
      await client.auth.forgotPassword(params.email)

      toast.success(I18n.t('devise.passwords.send_instructions'))

      navigateToLoginPage()
    } catch (error) {
      toast.error('Email not found')
    }
  }

  return (
    <div className="authentication-wrapper authentication-1 px-4">
      <div className="authentication-inner py-5">

        <div className="d-flex justify-content-center align-items-center">
          <div className="ui-w-65">
            <div className="w-100 position-relative">
              <img src={ SignInLogo } />
            </div>
          </div>
        </div>

        <form className="my-5">
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control name="email" ref={ register() } />
          </Form.Group>

          <div className="text-center">
            <Button type="submit" variant="primary" onClick={ handleSubmit(onSubmit) }>
              Send me reset password instructions
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AuthForgotPasswordView
