import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt } from '@fortawesome/pro-duotone-svg-icons'
import React, { useEffect, useState } from 'react'
import { Button, FormGroup } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import DashboardTrendChart from './dashboard_trend_chart'
import DashboardRow from './dashboard_row'
import Empty from '../shared/empty'
import FormSelect from '../shared/form_select'
import Disclaimer from '../shared/disclaimer'
import Modal from '../shared/modal'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'
import { useLocalStorage } from '../../lib/hooks'
import Humanize from 'humanize-plus'
import moment from 'moment'

export default function VisualizationsDashboardView (props) {
  const { chartDataMd5 } = useParams()
  const history = useHistory()

  // TODO: set focus year according to some datafact, so that we don't have to update this at start of every year
  const [filters, setFilters] = useLocalStorage('dbFilters', { region1_id: 71, region2_id: 21, focus_year: '2023', base_year: '2017', metric: 'licensees', license_type: 'hunt' })
  const [chartData, setChartData] = useState({})
  const [processing, setProcessing] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    async function fetchData () {
      if (chartDataMd5) {
        const chart = await client.charts.get(chartDataMd5)

        setFilters(chart.data)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData () {
      setProcessing(true)

      const response = await client.visualizations.getDashboard(filters)

      const data = {
        region1Id: response.region1_id,
        region2Id: response.region2_id,
        focusYear: response.focus_year,
        baseYear: response.base_year,
        years: response.years,
        chartColors: response.colors,
        regionsOptions: response.selectors.regions,
        yearsOptions: response.selectors.years,
        statisticOptions: response.selectors.statistics,
        licenseTypeOptions: response.selectors.license_types,
        labels: response.labels,
        dataCounts: response.data.counts,
        dataChanges: response.data.changes,
        dataYoy: response.data.yoy,
        metricTooltip: ''
      }

      if (filters.region1_id !== response.region1_id) {
        setFilters({ ...filters, region1_id: response.region1_id })
      }

      data.metricTooltip = metricTooltips(filters.metric)
      data.demoTooltip = demoTooltips(filters.metric)

      setProcessing(false)
      setChartData(data)
    }

    fetchData()
  }, [filters])

  const metricTooltips = (metric) => {
    const tooltip = {
      licensees: 'A License Holder represents an individual who holds one or more licenses granting primary privileges that are valid on at least one day during the applicable time period and for the applicable sport.',
      license_rate: 'License Rate is the percentage of the population for the reporting state or region that were license holders for the reporting period.',
      churn_rate: 'Churn Rate is the percentage of prior year license holders that were not license holders in the reporting year.',
      recruits: 'A New Recruit is a person who was a license holder in the reporting year but was not a license holder in the 5 years prior.'
    }[metric]
    return tooltip
  }

  const demoTooltips = (metric) => {
    const tooltip = {
      licensees: 'The percentage of all license holders represented by the respective demographic.',
      license_rate: 'The percentage of the resident population for the respective demographic that are license holders.',
      churn_rate: 'The percentage of prior year license holders in the respective demographic who did not hold a license in the reporting year.',
      recruits: 'The percentage of license holders in the respective demographic who are new recruits.'
    }[metric]
    return tooltip
  }

  const navigateToDashboardChartPage = (chart) => {
    history.push(`/visualizations/dashboard/${chart.data_md5}`)

    setModalVisible(true)
  }

  const shareChart = async () => {
    const chart = await client.charts.create({ data: filters })

    navigateToDashboardChartPage(chart)
  }

  const handleFilterChange = function (name, year) {
    setFilters({ ...filters, [name]: year })
  }

  const getValues = function (obj) {
    return Object.keys(obj).map(function (key) { return obj[key] })
  }

  const onCopyChartUrlToClipboard = () => {
    toast.success('Chart copied to clipboard')

    setModalVisible(false)
  }

  const getSharingChartUrl = () => {
    return window.location.href.replace(window.location.search, '')
  }

  if (!Object.keys(chartData).length) { return <Empty /> }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row'>
        <div className='col pr-1'>
          <div className='float-right'>
            <OverlayTrigger overlay={ <Tooltip>{ 'Share this chart' }</Tooltip> }>
              <Button variant='primary' className='float-right mr-2' onClick={ () => shareChart() }>
                <FontAwesomeIcon icon={ faShareAlt } />
              </Button>
            </OverlayTrigger>
          </div>
          <h4 className='font-weight-bold py-1 mb-1'>
            Hunting and Fishing License Dashboard
            <div className='text-muted text-tiny mt-1'>
              <span className='font-weight-normal'>
                <small>Today is { moment().format('MMMM D, YYYY') }</small>
              </span>
            </div>
          </h4>
        </div>
      </div>

      <Disclaimer />

      <Modal title={ 'Click link to copy to it to clipboard' } visible={ modalVisible }>
        <OverlayTrigger overlay={ <Tooltip>{ 'Copy to clipboard' }</Tooltip> }>
          <CopyToClipboard text={ getSharingChartUrl() } onCopy={ onCopyChartUrlToClipboard }>
            <Card body role='button'>{ getSharingChartUrl() }</Card>
          </CopyToClipboard>
        </OverlayTrigger>
      </Modal>

      <div className='row'>
        <div className='col'>
          <div className='card mb-1'>
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  <OverlayTrigger overlay={ <Tooltip>{ 'A Region represents a state or a collection of states.' }</Tooltip> }>
                    <FormGroup>
                      <label>Region 1</label>
                      <FormSelect
                        name={ 'region1_id' }
                        choices={ chartData.regionsOptions }
                        selected={ chartData.region1Id }
                        onUpdate={ handleFilterChange }
                      />
                    </FormGroup>
                  </OverlayTrigger>
                </div>

                <div className='col'>
                  <OverlayTrigger overlay={ <Tooltip>{ 'A Region represents a state or a collection of states.' }</Tooltip> }>
                    <FormGroup>
                      <label>Region 2</label>
                      <FormSelect
                        name={ 'region2_id' }
                        choices={ chartData.regionsOptions }
                        selected={ chartData.region2Id }
                        onUpdate={ handleFilterChange }
                      />
                    </FormGroup>
                  </OverlayTrigger>
                </div>

                <div className='col'>
                  <OverlayTrigger overlay={ <Tooltip>{ 'The Focus Year is the latest year in the range of years being reported.' }</Tooltip> }>
                    <FormGroup>
                      <label>Focus Year</label>
                      <FormSelect
                        name={ 'focus_year' }
                        choices={ chartData.yearsOptions }
                        selected={ filters.focus_year }
                        onUpdate={ handleFilterChange }
                      />
                    </FormGroup>
                  </OverlayTrigger>
                </div>

                <div className='col'>
                  <OverlayTrigger overlay={ <Tooltip>{ 'The Base Year is the earliest year in the range of years being reported.' }</Tooltip> }>
                    <FormGroup>
                      <label>Base Year</label>
                      <FormSelect
                        name={ 'base_year' }
                        choices={ chartData.yearsOptions }
                        selected={ filters.base_year }
                        onUpdate={ handleFilterChange }
                      />
                    </FormGroup>
                  </OverlayTrigger>
                </div>

                <div className='col'>
                  <OverlayTrigger overlay={ <Tooltip>{ chartData.metricTooltip }</Tooltip> }>
                    <FormGroup>
                      <label>Metric</label>
                      <FormSelect
                        name={ 'metric' }
                        choices={ chartData.statisticOptions }
                        selected={ filters.metric }
                        onUpdate={ handleFilterChange }
                      />
                    </FormGroup>
                  </OverlayTrigger>
                </div>

                <div className='col'>
                  <OverlayTrigger overlay={ <Tooltip>{ 'Sport is a licensed activity, such as Hunting or Fishing.' }</Tooltip> }>
                    <FormGroup>
                      <label>Sport</label>
                      <FormSelect
                        name='license_type'
                        choices={ chartData.licenseTypeOptions }
                        selected={ filters.license_type }
                        onUpdate={ handleFilterChange }
                      />
                    </FormGroup>
                  </OverlayTrigger>
                </div>
              </div>
              <div className='font-weight-normal'>
                <small>
                  <span className='strong'>*</span>
                  Data shown for multi-state regions such as
                  <span className='strong'> National </span>
                  and
                  <span className='strong'> SEAFWA </span>
                  presents summarized data for only those states participating in the Dashboard.</small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  <div className='row'>
                    <div className='col'>
                      <table id='atAGlanceData' className='table table-sm mb-0'>
                        <thead>
                          <tr>
                            <th colSpan='2'>
                              <h5 className='font-weight-bold mb-0'>{ chartData.labels.region1.year } at a Glance: { chartData.labels.region1.region }</h5>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className='font-weight-bold'>
                              <OverlayTrigger overlay={ <Tooltip>{ 'The percentage of all license holders for the focus year who were female.' }</Tooltip> }>
                                <span>Female</span>
                              </OverlayTrigger>
                            </td>
                            <td>{ Humanize.formatNumber(chartData.labels.region1.demographic_rates.female, 1) }%</td>
                          </tr>
                          <tr>
                            <td className='font-weight-bold'>
                              <OverlayTrigger overlay={ <Tooltip>{ metricTooltips('license_rate') }</Tooltip> }>
                                <span>License Rate</span>
                              </OverlayTrigger>
                            </td>
                            <td>{ Humanize.formatNumber(chartData.labels.region1.license_rate, 1) }%</td>
                          </tr>
                          <tr>
                            <td className='font-weight-bold'>
                              <OverlayTrigger overlay={ <Tooltip>{ metricTooltips('recruits') }</Tooltip> }>
                                <span>New Recruits</span>
                              </OverlayTrigger>
                            </td>
                            <td>{ Humanize.formatNumber(chartData.labels.region1.recruits, 1) }%</td>
                          </tr>
                          <tr>
                            <td className='font-weight-bold'>
                              <OverlayTrigger overlay={ <Tooltip>{ metricTooltips('churn_rate') }</Tooltip> }>
                                <span>Churn Rate</span>
                              </OverlayTrigger>
                            </td>
                            <td>{ Humanize.formatNumber(chartData.labels.region1.churn, 1) }%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='col'>
                      <table id='atAGlanceData' className='table table-sm mb-0'>
                        <thead>
                          <tr>
                            <th colSpan='2'>
                              <h5 className='font-weight-bold mb-0'>{ chartData.labels.region2.year } at a Glance: { chartData.labels.region2.region }</h5>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className='font-weight-bold'>
                              <OverlayTrigger overlay={ <Tooltip>{ 'The percentage of all license holders for the focus year who were female.' }</Tooltip> }>
                                <span>Female</span>
                              </OverlayTrigger>
                            </td>
                            <td>{ Humanize.formatNumber(chartData.labels.region2.demographic_rates.female, 1) }%</td>
                          </tr>
                          <tr>
                            <td className='font-weight-bold'>
                              <OverlayTrigger overlay={ <Tooltip>{ metricTooltips('license_rate') }</Tooltip> }>
                                <span>License Rate</span>
                              </OverlayTrigger>
                            </td>
                            <td>{ Humanize.formatNumber(chartData.labels.region2.license_rate, 1) }%</td>
                          </tr>
                          <tr>
                            <td className='font-weight-bold'>
                              <OverlayTrigger overlay={ <Tooltip>{ metricTooltips('recruits') }</Tooltip> }>
                                <span>New Recruits</span>
                              </OverlayTrigger>
                            </td>
                            <td>{ Humanize.formatNumber(chartData.labels.region2.recruits, 1) }%</td>
                          </tr>
                          <tr>
                            <td className='font-weight-bold'>
                              <OverlayTrigger overlay={ <Tooltip>{ metricTooltips('churn_rate') }</Tooltip> }>
                                <span>Churn Rate</span>
                              </OverlayTrigger>
                            </td>
                            <td>{ Humanize.formatNumber(chartData.labels.region2.churn, 1) }%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <DashboardTrendChart
                    years={ chartData.years }
                    values={ getValues(chartData.dataCounts.region1.overall) }
                    yoy={ getValues(chartData.dataYoy.region1.overall) }
                    label={ chartData.labels.region1.chartLabel }
                    chartColor={ chartData.chartColors.primary }
                  />
                </div>
                <div className='col'>
                  <DashboardTrendChart
                    years={ chartData.years }
                    values={ getValues(chartData.dataCounts.region2.overall) }
                    yoy={ getValues(chartData.dataYoy.region2.overall) }
                    label={ chartData.labels.region2.chartLabel }
                    chartColor={ chartData.chartColors.secondary }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  <table id='atAGlanceData' className='table table-sm mb-0'>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th colSpan="2">
                          <OverlayTrigger overlay={ <Tooltip>{ chartData.demoTooltip }</Tooltip> }>
                            <span>{ chartData.years[chartData.years.length - 1] } { chartData.labels.region1.chartLabel } { chartData.labels.region1.chartLabel === 'Licensees' ? 'Percent of Total' : '' } by Demographic</span>
                          </OverlayTrigger>
                        </th>
                        <th colSpan={2}>
                          <OverlayTrigger overlay={ <Tooltip>{ 'These charts show the increase/decrease from each year in the range to the Focus Year' }</Tooltip> }>
                            <span>
                              Change Since&nbsp;
                              { chartData.years[0] }
                            </span>
                          </OverlayTrigger>
                        </th>
                      </tr>
                    </thead>
                    { Object.keys(chartData.dataCounts.region1).map((category, index) => {
                      return (
                        <tbody key={index}>
                          <DashboardRow
                            key={ index }
                            label={ category }
                            category={ category }
                            region= { 'region1' }
                            chartData={ chartData }
                            metric={ filters.metric }
                            chartColors={ chartData.chartColors }
                          />
                        </tbody>
                      )
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
