// Auto update layout
if (window.layoutHelpers) {
  window.layoutHelpers.setAutoUpdate(true)
}

$(function () {
  // Initialize sidenav togglers
  $('body').on('click', '.layout-sidenav-toggle', function (e) {
    e.preventDefault()
    window.layoutHelpers.toggleCollapsed()
  })

  // Swap dropdown menus in RTL mode
  if ($('html').attr('dir') === 'rtl') {
    $('#layout-navbar .dropdown-menu').toggleClass('dropdown-menu-right')
  }
})
