import { get } from '../client'

const visualizationClient = {
  getDashboard: async (filters) => {
    return (await get('/api/visualizations/dashboard', filters))
  },

  getLicenseHolders: async (filters) => {
    return (await get('/api/visualizations/license_holders', filters))
  },

  getPurchases: async (filters) => {
    return (await get('/api/visualizations/purchases', filters))
  }
}

export { visualizationClient }
