import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { useParams, useHistory } from 'react-router-dom'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function RoleShowView (props) {
  const { roleId } = useParams()
  const history = useHistory()

  const [role, setRole] = useState({})
  const [processing, setProcessing] = useState(true)

  useEffect(() => {
    async function fetchData () {
      setRole(await client.roles.get(roleId))
      setProcessing(false)
    }

    fetchData()
  }, [])

  const navigateToRoleListPage = () => {
    history.push('/roles')
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToRoleListPage }>Roles</Breadcrumb.Item>
            <Breadcrumb.Item active>{ role.name }</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div>
        Name: { role.name }
      </div>
    </div>
  )
}

RoleShowView.propTypes = {
  roleId: PropTypes.number
}
