import { create, get, remove, update } from '../client'

const regionClient = {
  getAll: async () => {
    return (await get('/api/regions')).regions
  },

  get: async (regionId) => {
    return get(`/api/regions/${regionId}`)
  },

  create: async (params) => {
    return create('/api/regions', params)
  },

  update: async (regionId, params) => {
    return update(`/api/regions/${regionId}`, params)
  },

  remove: async (regionId) => {
    return remove(`/api/regions/${regionId}`)
  }
}

export { regionClient }
