import { fetch } from 'cross-fetch'
import ReactOnRails from 'react-on-rails'
import queryString from 'query-string'
import Cookies from 'universal-cookie'

import { authClient } from './client/auth'
import { abilityClient } from './client/abilities'
import { chartClient } from './client/charts'
import { datafileClient } from './client/datafiles'
import { licenseClient } from './client/licenses'
import { licenseTypeClient } from './client/license_types'
import { regionClient } from './client/regions'
import { roleClient } from './client/roles'
import { populationClient } from './client/populations'
import { providerClient } from './client/providers'
import { stateClient } from './client/states'
import { tokenClient } from './client/tokens'
import { userClient } from './client/users'
import { visualizationClient } from './client/visualizations'
import { councilClient } from './client/council'

import { faqQuestionClient } from './client/faq/questions'
import { faqCategoryClient } from './client/faq/categories'

const cookies = new Cookies()

const parseResponse = async (response) => {
  if (response.headers.get('authorization')) {
    cookies.set('bearer', response.headers.get('authorization'), { path: '/' })
  }

  let data

  const text = await response.text()

  try { data = JSON.parse(text) } catch { /* no-op */ }

  if (data) {
    if (response.status >= 200 && response.status < 300) {
      return data
    } else {
      if (response.status === 401) {
        cookies.remove('bearer')
      }

      const error = new Error(data.message || data.error)

      error.errors = data.errors
      error.name = data.name

      throw error
    }
  }
}

// Includes CSRF headers used to prevent XSS attacks
const headers = () => {
  const headers = ReactOnRails.authenticityHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json'
  })

  headers.Authorization = cookies.get('bearer')

  return headers
}

const get = async (url, params = {}) => {
  if (params) {
    url = `${url}?${queryString.stringify(params)}`
  }

  const response = await fetch(url, { headers: headers() })

  return parseResponse(response)
}

const create = async (url, params) => {
  const response = await fetch(url, { method: 'POST', body: JSON.stringify(params), headers: headers() })

  return parseResponse(response)
}

const update = async (url, params) => {
  const response = await fetch(url, { method: 'PATCH', body: JSON.stringify(params), headers: headers() })

  return parseResponse(response)
}

const remove = async (url) => {
  const response = await fetch(url, { method: 'DELETE', headers: headers() })

  return parseResponse(response)
}

/**
 * Public client interface
 */
const client = {
  auth: authClient,
  abilities: abilityClient,
  charts: chartClient,
  datafiles: datafileClient,
  licenses: licenseClient,
  licenseTypes: licenseTypeClient,
  regions: regionClient,
  roles: roleClient,
  providers: providerClient,
  populations: populationClient,
  states: stateClient,
  tokens: tokenClient,
  users: userClient,
  visualizations: visualizationClient,
  council: councilClient,
  faq: {
    categories: faqCategoryClient,
    questions: faqQuestionClient
  }
}

export { client, create, fetch, get, remove, update }
