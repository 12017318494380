import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowUp } from '@fortawesome/pro-duotone-svg-icons'
import DataNotice from './data_notice'

const WelcomeView = () => {
  return (
    <div>
      <Row className='mb-4'>
        <Col>
          <Card className='h-100'>
            <Card.Header>
              <h1>Welcome</h1>
            </Card.Header>
            <Card.Body>
              <p>Welcome to the Real Time Hunting and Fishing License Data Dashboard</p>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className='h-100'>
            <Card.Header>
              <h1>About</h1>
            </Card.Header>
            <Card.Body>
              <p>This tool has been constructed to provide insight regional and national trends in hunting and fishing license purchases and is designed to inform R3 efforts and collaboration between stakeholders. </p>
              <p>
                <Link className='float-right' to='/about'>More info about R3Dashboard</Link>
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col>
          <Card className='h-100'>
            <Card.Body>
              <p>Visualizations of the data are provided to the public free of charge and break down license purchases into sales, active participants, and trends over time.</p>
              <h1 className='text-danger'><FontAwesomeIcon icon={ faArrowLeft } transform='rotate-45' /></h1>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className='h-100'>
            <Card.Body>
              <p>Please take some time to review the FAQ section to learn about how the dashboard is constructed,.</p>
              <p>
                <Link className='float-right' to='/help'>More info about Methodology</Link>
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className='h-100'>
            <Card.Body>
              <p>For state agencies, sign in using the link above to access your state dashboard and update any information about your account or data transfer protocols.</p>
              <h1 className='text-primary float-right'><FontAwesomeIcon icon={ faArrowUp } /></h1>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DataNotice />
    </div>
  )
}

export default WelcomeView
