import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAuth } from '../../lib/auth/context'
import { client } from '../../lib/client'

const AuthUnlockView = (props) => {
  const { isAuthenticated } = useAuth()
  const { token } = useParams()
  const history = useHistory()

  if (isAuthenticated) {
    history.push('/')
  }

  useEffect(() => {
    async function confirm () {
      await client.auth.unlock(token)

      toast.success('Account was unlocked successfully')

      history.push('/users/sign_in')
    }

    confirm()
  }, [])

  return <div></div>
}

export default AuthUnlockView
