import PropTypes from 'prop-types'
import Humanize from 'humanize-plus'
import React, { useEffect, useState } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Form from 'react-bootstrap/Form'
import { useParams, useHistory } from 'react-router-dom'
import Spinner from '../shared/spinner'
import { DEMOGRAPHICS } from '../../constants'
import { client } from '../../lib/client'

export default function PopulationFormView (props) {
  const { year } = useParams()
  const history = useHistory()

  const [states, setStates] = useState([])
  const [populations, setPopulations] = useState([])
  const [processing, setProcessing] = useState(true)

  useEffect(() => {
    async function fetchData () {
      setPopulations(await client.populations.getAllByYear(year))
      setStates(await client.states.getAll())
      setProcessing(false)
    }

    fetchData()
  }, [])

  const navigateToPopulationListPage = () => {
    history.push('/populations')
  }

  const getPopulationByStateAndDemographic = (stateId, demographic) => {
    return populations.find((pop) => pop.state_id === stateId && pop.demographic === demographic)
  }

  const updatePopulation = (population, total) => {
    return client.populations.update(population.id, { total })
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToPopulationListPage }>Populations</Breadcrumb.Item>
            <Breadcrumb.Item>{ year }</Breadcrumb.Item>
            <Breadcrumb.Item active>Edit</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <table className='table table-sm'>
        <thead>
          <tr>
            <th>State</th>

            {
              Object.keys(DEMOGRAPHICS).map((demographic) =>
                <th className='text-center' key={ demographic }>{ Humanize.capitalize(demographic) }</th>
              )
            }
          </tr>
        </thead>

        <tbody>
          {
            states.map((state) =>
              <tr key={ state.id }>
                <td><strong>{ state.abbreviation.toUpperCase() }</strong></td>

                {
                  Object.keys(DEMOGRAPHICS).map((demographic) => {
                    const population = getPopulationByStateAndDemographic(state.id, demographic)

                    return (
                      <td key={ population.id }>
                        <Form.Control
                          size='sm'
                          type='text'
                          className='text-center'
                          defaultValue={ population.total }
                          onChange={ (event) => updatePopulation(population, event.target.value) } />
                      </td>
                    )
                  })
                }
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  )
}

PopulationFormView.propTypes = {
  year: PropTypes.number
}
