import { create, get, remove, update } from '../client'

const providerClient = {
  getAll: async () => {
    return (await get('/api/providers')).providers
  },

  get: async (providerId) => {
    return get(`/api/providers/${providerId}`)
  },

  create: async (params) => {
    return create('/api/providers', params)
  },

  update: async (providerId, params) => {
    return update(`/api/providers/${providerId}`, params)
  },

  remove: async (providerId) => {
    return remove(`/api/providers/${providerId}`)
  }
}

export { providerClient }
