import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { TrixEditor } from 'react-trix'

import 'trix/dist/trix'

const Trix = (props) => {
  const [editor, setEditor] = useState()

  const onEditorReady = (editor) => {
    setEditor(editor)
  }

  return (
    <Controller
      name={ props.name }
      control={ props.control }
      render={
        ({ onChange, value }) => {
          // HACK: There is no way to change the default value after loading the component,
          //   only via the native Trix component API.
          if (editor && (editor.element.value === '' && value !== '')) {
            editor.loadHTML(value)
          }

          return <TrixEditor
                   value={ value }
                   onChange={ onChange }
                   onEditorReady={ onEditorReady } />
        }
      } />
  )
}

Trix.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object
}

export default Trix
