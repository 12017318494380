import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faPlus } from '@fortawesome/pro-duotone-svg-icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function RoleListView () {
  const [roles, setRoles] = useState([])
  const [processing, setProcessing] = useState(true)

  const history = useHistory()

  useEffect(() => {
    async function fetchData () {
      setRoles(await client.roles.getAll())
      setProcessing(false)
    }

    fetchData()
  }, [])

  const navigateToRoleNewView = () => {
    history.push('/roles/new')
  }

  const navigateToRoleEditView = (roleId) => {
    history.push(`/roles/${roleId}/edit`)
  }

  const navigateToRoleShowView = (roleId) => {
    history.push(`/roles/${roleId}`)
  }

  const removeRole = async (roleId) => {
    setProcessing(true)

    await client.roles.remove(roleId)

    setRoles(await client.roles.getAll())
    setProcessing(false)
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-10'>
          <Breadcrumb>
            <Breadcrumb.Item active>Roles</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className='col-2'>
          <Button variant='success' type='submit' className='float-right' onClick={ navigateToRoleNewView }>
            <FontAwesomeIcon icon={ faPlus } />
            &nbsp;New
          </Button>
        </div>
      </div>

      <table className='table'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {
            roles.map((role) => (
              <tr key={ role.id }>
                <td>
                  <a href='#' onClick={ () => navigateToRoleShowView(role.id) }>{ role.name }</a>
                </td>

                <td>
                  <Button variant='primary' className='float-left mr-2' onClick={ () => navigateToRoleEditView(role.id) }>
                    <FontAwesomeIcon icon={ faEdit } />
                  </Button>

                  <Button
                    variant='danger'
                    className='float-left'
                    onClick={
                      () => {
                        if (window.confirm('Are you sure you wish to delete this role?')) { removeRole(role.id) }
                      }
                    }>
                    <FontAwesomeIcon icon={ faTrash } />
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}
