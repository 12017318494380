import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { useParams, useHistory } from 'react-router-dom'
import { chunk } from 'underscore'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'

export default function RegionShowView () {
  const { regionId } = useParams()
  const history = useHistory()

  const [states, setStates] = useState([])
  const [region, setRegion] = useState({})
  const [processing, setProcessing] = useState(true)

  useEffect(() => {
    async function fetchData () {
      setStates(await client.states.getAll())
      setRegion(await client.regions.get(regionId))
      setProcessing(false)
    }

    fetchData()
  }, [])

  const navigateToRegionListPage = () => {
    history.push('/regions')
  }

  const isStateIncluded = (state) => {
    return region.states && region.states.map(s => s.id).includes(state.id)
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToRegionListPage }>Regions</Breadcrumb.Item>
            <Breadcrumb.Item active>{ region.name }</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div>
        Name: { region.name }
      </div>

      <div>
        Subdomain: { region.subdomain }
      </div>

      <fieldset>
        <legend>States</legend>

        {
          chunk(states, 4).map((chunkedStates) =>
            <div className='row mb-3' key={ chunkedStates.toString() }>
              {
                chunkedStates.map((state) =>
                  <div className='col-3' key={ state.id }>
                    <Form.Check
                      disabled={ true }
                      key={ state.id }
                      defaultChecked={ isStateIncluded(state) }
                      label={ state.name } />
                  </div>
                )
              }
            </div>
          )
        }
      </fieldset>
    </div>
  )
}

RegionShowView.propTypes = {
  regionId: PropTypes.number
}
