import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-duotone-svg-icons'
import Humanize from 'humanize-plus'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import FormSelect from '../shared/form_select'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'
import I18n from '../../lib/i18n'

export default function StateFormView (props) {
  const { stateId } = useParams()
  const history = useHistory()

  const [selectedTab, setSelectedTab] = useState(history.location.hash.substring(1) || 'configuration')
  const [state, setState] = useState({})
  const [providers, setProviders] = useState([])
  const [licenses, setLicenses] = useState([])
  const [licenseTypes, setLicenseTypes] = useState([])
  const [processing, setProcessing] = useState(true)

  const { handleSubmit, register, reset } = useForm({ defaultValues: { active: true, summarization: 'none' } })

  // Update form default values for edit action
  useEffect(() => {
    if (state.id) {
      state.license_type_ids = state.license_types.map((lt) => String(lt.id))

      reset(state)
    }
  }, [state])

  useEffect(() => {
    async function fetchData () {
      setProviders(await client.providers.getAll())
      setLicenseTypes(await client.licenseTypes.getAll())

      if (isEditForm()) {
        setState(await client.states.get(stateId))
        setLicenses(await client.licenses.getAll(stateId))
      }

      setProcessing(false)
    }

    fetchData()
  }, [])

  const isEditForm = () => !!stateId

  const licenseRowClass = (license) => {
    const classes = []

    if (license.status === 'pending') {
      classes.push('table-danger')
    }

    return classes.join(' ')
  }

  const pendingLicenseCount = () => {
    return licenses.filter((license) => license.status === 'pending').length
  }

  const navigateToStateListPage = () => {
    history.push('/states')
  }

  const navigateToStatePage = () => {
    history.push(`/states/${stateId}`)
  }

  const navigateToLicenseEditView = (licenseId) => {
    history.push('#licenses')
    history.push(`/states/${stateId}/licenses/${licenseId}/edit`)
  }

  const onTabChange = (tabName) => {
    setSelectedTab(tabName)
  }

  const onSubmit = async (params) => {
    setProcessing(true)

    try {
      if (isEditForm()) {
        await client.states.update(stateId, params)

        toast.success('State was updated successfully')
      } else {
        await client.states.create(params)

        toast.success('State was created successfully')
      }

      navigateToStateListPage()
    } catch (error) {
      setProcessing(false)

      toast.error(error.errors.join(', '))
    }
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item onClick={ navigateToStateListPage }>States</Breadcrumb.Item>

            {
              isEditForm() ? (
                <>
                  <Breadcrumb.Item onClick={ navigateToStatePage }>
                    { state.name }
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </>
              ) : (
                <Breadcrumb.Item active>New</Breadcrumb.Item>
              )
            }
          </Breadcrumb>
        </div>
      </div>

      <Form>
        <Form.Group>
          <Form.Label>
            Name

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control
            type='text'
            name='name'
            disabled={ isEditForm() }
            ref={ register() } />
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Abbreviation

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control
            type='text'
            name='abbreviation'
            disabled={ isEditForm() }
            ref={ register() } />
        </Form.Group>

        <Form.Check
          inline
          id='state_active'
          name='active'
          label='Active'
          type={ 'checkbox' }
          ref={ register() } />

        <Tabs
          activeKey={ selectedTab }
          onSelect={ (tabName) => onTabChange(tabName) }>
          <Tab eventKey='configuration' title='Configuration' className='p-3'>
            <Form.Group>
              <Form.Label>Provider</Form.Label>

              <FormSelect
                name='provider_id'
                includeBlank={ true }
                choices={ providers.map((provider) => ({ value: provider.id, text: provider.name })) }
                register={ register } />
            </Form.Group>

            <Form.Group>
              <Form.Label>Files Storage</Form.Label>

              <Form.Control as='select' name='files_storage' ref={ register }>
                <option></option>
                <option value='s3'>S3</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Files Location</Form.Label>

              <Form.Control type='text' name='files_location' ref={ register() } />
            </Form.Group>

            <Form.Group>
              <Form.Label>Files Format</Form.Label>

              <Form.Control as='select' name='files_format' ref={ register }>
                <option></option>
                <option value='csv'>CSV</option>
                <option value='json'>JSON</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Summarization</Form.Label>

              <div className='row'>
                <div className={ 'col ' + (state.summarization === 'threshold' ? 'col-10' : 'col-12') }>
                  <Form.Control
                    as='select'
                    name='summarization'
                    ref={ register }
                    onChange={ (e) => setState({ ...state, summarization: e.target.value }) }>
                    <option value='successful'>{ I18n.t('activerecord.attributes.state.summarization.successful') }</option>
                    <option value='threshold'>{ I18n.t('activerecord.attributes.state.summarization.threshold') }</option>
                    <option value='none'>{ I18n.t('activerecord.attributes.state.summarization.none') }</option>
                  </Form.Control>
                </div>

                <div className='col col-2'>
                  {
                    state.summarization === 'threshold' && (
                      <InputGroup>
                        <Form.Control type='number' name='threshold' ref={ register() } />
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup>
                    )
                  }
                </div>
              </div>
            </Form.Group>
          </Tab>

          <Tab eventKey='license_types' title='License Types' className='p-3'>
            <div className='row'>
              {
                licenseTypes.map((licenseType, index) =>
                  <div key={ licenseType.id } className='col col-2 mr-3'>
                    <Form.Check
                      name={ 'license_type_ids[]' }
                      id={ `state_license_type_${licenseType.name}` }
                      type='checkbox'
                      inline
                      label={ licenseType.name }
                      value={ licenseType.id }
                      key={ licenseType.name }
                      ref={ register() } />
                  </div>
                )
              }
            </div>
          </Tab>

          {
            isEditForm() && (
              <Tab
              eventKey='licenses'
              title={
                <React.Fragment>
                  Licenses

                  {
                    !!pendingLicenseCount() &&
                      <Badge variant='danger' style={ { marginLeft: '5px' } }>{ pendingLicenseCount() }</Badge>
                  }
                </React.Fragment>
              }
              className='p-3'>
                <div className='row'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>State ID</th>
                        <th>Description</th>
                        <th>Type</th>
                        <th>Residency</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        licenses.map((license, index) =>
                          <tr key={ license.id } className={ licenseRowClass(license) }>
                            <td>{ license.state_license_id }</td>
                            <td>{ license.description }</td>
                            <td>{ license.license_type && license.license_type.name }</td>
                            <td>{ license.residency }</td>
                            <td>{ Humanize.capitalize(license.status) }</td>

                            <td>
                              <Button variant='primary' className='float-left mr-2' onClick={ () => navigateToLicenseEditView(license.id) }>
                                <FontAwesomeIcon icon={ faEdit } />
                              </Button>
                            </td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </Tab>
            )
          }
        </Tabs>

        <div className='row'>
          <div className='col'>
            <div className='pt-3'>
              <Button variant='warning' type='submit' onClick={ handleSubmit(onSubmit) }>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

StateFormView.propTypes = {
  stateId: PropTypes.number
}
