import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../shared/spinner'
import { client } from '../../lib/client'
import I18n from '../../lib/i18n'

export default function LicenseFormView (props) {
  const { stateId, licenseId } = useParams()
  const history = useHistory()

  const [license, setLicense] = useState({})
  const [licenseTypes, setLicenseTypes] = useState([])
  const [processing, setProcessing] = useState(true)

  const { handleSubmit, register, reset } = useForm({ defaultValues: { active: true } })

  // Update form default values for edit action
  useEffect(() => {
    if (Object.keys(license).length) {
      license.license_type_id = license.license_type.id

      reset(license)
    }
  }, [license])

  useEffect(() => {
    async function fetchData () {
      setLicenseTypes(await client.licenseTypes.getAllByState(stateId))

      if (isEditForm()) {
        setLicense(await client.licenses.get(stateId, licenseId))
      }

      setProcessing(false)
    }

    fetchData()
  }, [])

  const isEditForm = () => !!licenseId

  const onSubmit = async (params) => {
    setProcessing(true)

    try {
      if (isEditForm()) {
        await client.licenses.update(stateId, licenseId, params)

        toast.success('License was updated successfully')
      } else {
        await client.licenses.create(stateId, params)

        toast.success('License was created successfully')
      }

      history.goBack()
    } catch (error) {
      setProcessing(false)

      toast.error(error.errors.join(', '))
    }
  }

  return (
    <div>
      <Spinner visible={ processing } />

      <div className='row mb-4'>
        <div className='col-12'>
          <Breadcrumb>
            <Breadcrumb.Item>Licenses</Breadcrumb.Item>

            {
              isEditForm() ? (
                <>
                  <Breadcrumb.Item>{ license.state_license_id }</Breadcrumb.Item>
                  <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </>
              ) : (
                <Breadcrumb.Item active>New</Breadcrumb.Item>
              )
            }
          </Breadcrumb>
        </div>
      </div>

      <Form>
        <Form.Group>
          <Form.Label>
            State License ID

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control
            type='text'
            name='state_license_id'
            ref={ register() }
            disabled={ true } />
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Description

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control
            type='text'
            name='description'
            ref={ register() }
            disabled={ true } />
        </Form.Group>

        <Form.Group>
          <Form.Label>State License Type</Form.Label>

          <Form.Control
            type='text'
            name='state_license_type'
            ref={ register() }
            disabled={ true } />
        </Form.Group>

        <Form.Group>
          <Form.Label>Residency</Form.Label>

          <Form.Control
            type='text'
            name='residency'
            ref={ register() }
            disabled={ true } />
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Duration

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control
            type='text'
            name='duration'
            ref={ register() }
            disabled={ true } />
        </Form.Group>

        <hr />

        <Form.Group>
          <Form.Label>
            Status

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control as='select' name='status' ref={ register }>
            <option value='pending'>{ I18n.t('activerecord.attributes.license.status.pending') }</option>
            <option value='confirmed'>{ I18n.t('activerecord.attributes.license.status.confirmed') }</option>
            <option value='ignored'>{ I18n.t('activerecord.attributes.license.status.ignored') }</option>
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>
            License Type

            <span className='ml-1 text-primary'>*</span>
          </Form.Label>

          <Form.Control as='select' name='license_type_id' ref={ register }>
            { licenseTypes.map((licenseType) => <option key={ licenseType.id } value={ licenseType.id }>{ licenseType.name }</option>) }
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Privilege</Form.Label>

          <Form.Control
            type='text'
            name='privilege'
            ref={ register() } />
        </Form.Group>

        <div className='row'>
          <div className='col'>
            <div className='pt-3'>
              <Button variant='warning' type='submit' onClick={ handleSubmit(onSubmit) }>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

LicenseFormView.propTypes = {
  licenseId: PropTypes.number
}
